import "./add_customer_modal.css";
import React from 'react';

import axios from 'axios';
import { BASE_URL } from "../../../base_url";

//Dialog Imports//
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import MenuItem from '@mui/material/MenuItem';


class Add_Customer_modal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        customerName:'',
        customerAddress:'',
        customerType:'',
        discount:[]
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(){

    const url = BASE_URL + 'Get_Discount.php';
    axios.get(url).then(response => response.data)
    .then((data) => {
      this.setState({ discount: data })
      console.log(this.state.discount)
     })
  }

  handleSubmit(event) {
    event.preventDefault();
    // Check if required fields are empty
    if (
      !this.state.customerName.trim() ||
      !this.state.customerAddress.trim()
    ) {
      alert('Fields are empty. Please fill in all required fields.');
      return; // Do not submit the form
    }
  
    let formData = new FormData();
    formData.append('customerName', this.state.customerName);
    formData.append('customerAddress', this.state.customerAddress);
    formData.append('customerType', this.state.customerType);
  
    axios({
      method: 'POST',
      url: BASE_URL + 'Add_Customer.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
    })
      .then((response) => {
        // Handle success
        console.log(response);
        alert('New Customer Successfully Added.');
        window.location.reload();
      })
      .catch((error) => {
        // Handle error
        console.log(error);
      });
  }

  handleChange(event) {
    const state = this.state
    state[event.target.name] = event.target.value
    this.setState(state);
  }


  render() {
    const { isOpen, setIsOpen } = this.props; // Access props here

    const handleClose = () => {
      setIsOpen(false);
    };

    return (
      <div className="add-customer-modal-container">
        <Dialog open={isOpen} fullWidth>
          <form onSubmit={this.handleSubmit}>
            <DialogTitle>Create Customer</DialogTitle>
            <DialogContent>

              <TextField
                autoFocus
                margin="dense"
                id="customerName"
                name="customerName"
                label="Customer's Name"
                type="text"
                fullWidth
                required
                variant="outlined"
                value={this.state.customerName}
                onChange={this.handleChange}
              />

              <TextField
                autoFocus
                margin="dense"
                id="customerAddress"
                name="customerAddress"
                label="Customer's Address"
                type="text"
                fullWidth
                required
                variant="outlined"
                value={this.state.customerAddress}
                onChange={this.handleChange}
              />

              <TextField
                label="Customer Type"
                margin="dense"
                id="customerType"
                name="customerType" // Set the name attribute to match the state variable name
                value={this.state.customerType}
                onChange={this.handleChange}
                fullWidth
                required
                select
                displayEmpty
              >
                <MenuItem value="" disabled>Select Customer Type</MenuItem>
                {this.state.discount.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.discount_name}
                  </MenuItem>
                ))}
              </TextField>

            </DialogContent>
              <DialogActions sx={{padding:'30px'}}>
                <Button variant="contained" color="success" onClick={this.handleSubmit} >Submit</Button>
                <Button variant="contained" color="error" onClick={handleClose}>Cancel</Button>
              </DialogActions>
              </form>
          </Dialog>
      </div>
    )
  }
}

export default Add_Customer_modal

