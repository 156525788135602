import "./reports.css";
import React, { useState} from 'react';

import Header from "../../components/header/Header";
import ReportHeader from "../../components/report-components/report-header/Report_Header"
import ReportTable from "../../components/report-components/report-table/Report_Table";


function Reports() {

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedMethod, setSelectedMethod] = useState('');

  return (
    <div className='reports'>
      <Header/>

      <div className="reports-header">
        <ReportHeader
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setSelectedMethod={setSelectedMethod}
        />
      </div>
      <hr/>

      <div className="reports-tables">
        <ReportTable
          startDate={startDate}
          endDate={endDate}
          selectedMethod={selectedMethod}
        />
      </div>
      
    </div>
  )
}

export default Reports
