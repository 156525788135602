import "./edit_discount_modal.css";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from "../../../base_url";

// Dialog Imports
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

function Edit_Discount_Modal(props) {

  const [formData, setFormData] = useState({
    discountId: null,
    discountName: null
  });

  useEffect(() => {
    // When the modal is opened, populate the form with selected product data
    if (props.isOpen && props.selectedDiscount) {
      const { id, discount_name } = props.selectedDiscount;
      setFormData({
        discountId: id,
        discountName: discount_name
      });
    }
  }, [props.isOpen, props.selectedDiscount]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('discountId', formData.discountId);
    formDataToSend.append('discountName', formData.discountName);

    axios({
      method: 'POST',
      url: BASE_URL + '/src/php/Get_Discount.php',
      data: formDataToSend,
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
    })
      .then((response) => {
        // Handle success
        console.log(response);
        alert('Customer Type Successfully Updated.');
        props.onClose(false);
        window.location.reload();
      })
      .catch((error) => {
        // Handle error
        console.log(error);
        alert('Customer Type Successfully Updated.');
        props.onClose(false);
        window.location.reload();
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClose = () => {
    props.onClose(false);
  };

  return (
    <div className="edit-discount-modal-container">
      <Dialog open={props.isOpen} fullWidth>
          <form onSubmit={handleSubmit}>
            <DialogTitle>Edit Customer Type</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="discountId"
                  name="discountId"
                  label="Discount ID"
                  type="text"
                  fullWidth
                  required
                  variant="outlined"
                  value={formData.discountId || ''}
                  onChange={handleChange}
                />

                <TextField
                  autoFocus
                  margin="dense"
                  id="discountName"
                  name="discountName"
                  label="Discount Name"
                  type="text"
                  fullWidth
                  required
                  variant="outlined"
                  value={formData.discountName || ''}
                  onChange={handleChange}
                />            

              </DialogContent>
              <DialogActions sx={{ padding: '30px' }}>
                <Button variant="contained" color="error" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="contained" type="submit">
                  Submit
                </Button>
            </DialogActions>
          </form>
        </Dialog>
    </div>
  )
}

export default Edit_Discount_Modal
