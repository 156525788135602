import "./edit_user_modal.css";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from "../../../base_url";

// Dialog Imports
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';

function Edit_User_Modal(props) {

  const [formData, setFormData] = useState({
    userId: null,
    userName: null,
    userPassword: null,
    userRole: null,
  });

  useEffect(() => {
    // When the modal is opened, populate the form with selected product data
    if (props.isOpen && props.selectedUsers) {
      const { id, username, password, role } = props.selectedUsers;
      setFormData({
        userId: id,
        userName: username,
        userPassword: password,
        userRole: role
      });
    }
  }, [props.isOpen, props.selectedUsers]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('userId', formData.userId);
    formDataToSend.append('userName', formData.userName);
    formDataToSend.append('userPassword', formData.userPassword);
    formDataToSend.append('userRole', formData.userRole);

    axios({
      method: 'POST',
      url: BASE_URL + 'Get_User.php',
      data: formDataToSend,
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
    })
      .then((response) => {
        // Handle success
        console.log(response);
        alert('User Successfully Updated.');
        props.onClose(false);
        window.location.reload();
      })
      .catch((error) => {
        // Handle error
        console.log(error);
        alert('User Successfully Updated.');
        props.onClose(false);
        window.location.reload();
      });
  };

  const handleClose = () => {
    props.onClose(false);
  };
  return (
    <div className="edit-user-modal-container">
      <Dialog open={props.isOpen} fullWidth>
          <form onSubmit={handleSubmit}>
            <DialogTitle>Edit User</DialogTitle>
              <DialogContent>

                <TextField
                  autoFocus
                  margin="dense"
                  id="userId"
                  name="userId"
                  label="User ID"
                  type="text"
                  fullWidth
                  required
                  hidden
                  variant="outlined"
                  value={formData.userId || ''}
                  onChange={handleChange}
                />

                <TextField
                  autoFocus
                  margin="dense"
                  id="userName"
                  name="userName"
                  label="Username"
                  type="text"
                  fullWidth
                  required
                  variant="outlined"
                  value={formData.userName || ''}
                  onChange={handleChange}
                />

                <TextField
                  autoFocus
                  margin="dense"
                  id="userPassword"
                  name="userPassword"
                  label="Password"
                  type="password"
                  fullWidth
                  required
                  variant="outlined"
                  value={formData.userPassword || ''}
                  onChange={handleChange}
                />

                <TextField
                  autoFocus
                  margin="dense"
                  id="userRole"
                  name="userRole"
                  label="User Role"
                  select
                  fullWidth
                  required
                  variant="outlined"
                  value={formData.userRole || ''}
                  onChange={handleChange}  
                >
                  <MenuItem value="1">Administrator</MenuItem>
                  <MenuItem value="2">Cashier</MenuItem>
                </TextField>

                </DialogContent>
                <DialogActions sx={{ padding: '30px' }}>
                  <Button variant="contained" color="success" type="submit">
                    Submit
                  </Button>
                  <Button variant="contained" color="error" onClick={handleClose}>
                    Cancel
                  </Button>
              </DialogActions>
          </form>
        </Dialog>
    </div>
  )
}

export default Edit_User_Modal
