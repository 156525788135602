import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

//PAGES//
import LoginPage from "./pages/login-page/Login_page";
import Dashboard from "./pages/dashboard/Dashboard";
import UserPage from "./pages/users/Users";

import CashRegister from "./pages/cash-register/Cash_Register";

import Products from "./pages/products/Products";
import ProductCategory from "./pages/product-category/Product_category";
import ProductBrand from "./pages/product-brand/Product_brand";

import Customers from "./pages/customers/Customer";
import CustomerDiscount from "./pages/customer-discount/Customer_Discount"

import Transactions from "./pages/transactions/Transactions";

import Inventory from "./pages/inventory/Inventory";

import Reports from "./pages/reports/Reports";

function App() {
  return (
    <Router>
      <div id="container">
        <Routes>

          <Route exact path="/" element={<LoginPage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/users" element={<UserPage />} />

          <Route path="/cash-register" element={<CashRegister />} />

          <Route path="/products" element={<Products />} />
          <Route path="/product-category" element={<ProductCategory />} />
          <Route path="/product-brand" element={<ProductBrand />} />

          <Route path="/customers" element={<Customers />} />
          <Route path="/customer-discount" element={<CustomerDiscount />} />

          <Route path="/transactions" element={<Transactions />} />

          <Route path="/inventory" element={<Inventory />} />

          <Route path="/reports" element={<Reports />} />



        </Routes>
      </div>
    </Router>
  );
}

export default App;
