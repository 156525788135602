import './add_user_modal.css';
import React from 'react';

import axios from 'axios';
import { BASE_URL } from "../../../base_url";

//Dialog Imports//
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import MenuItem from '@mui/material/MenuItem';


class Add_User_Modal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        userName:'',
        userPassword:'',
        userRole:'',
        roles:[]
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(){

    const url = BASE_URL + 'Get_Roles.php';
    axios.get(url).then(response => response.data)
    .then((data) => {
      this.setState({ roles: data })
      console.log(this.state.roles)
     })
  }

  handleSubmit(event) {
    event.preventDefault();
  
    let formData = new FormData();
    formData.append('userName', this.state.userName);
    formData.append('userPassword', this.state.userPassword);
    formData.append('userRole', this.state.userRole);
  
    axios({
      method: 'POST',
      url: BASE_URL + 'Add_User.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
    })
      .then((response) => {
        // Handle success
        console.log(response);
        alert('New User Successfully Added.');
        window.location.reload();
      })
      .catch((error) => {
        // Handle error
        console.log(error);
      });
  }

  handleChange(event) {
    const state = this.state
    state[event.target.name] = event.target.value
    this.setState(state);
  }


  render() {
    const { isOpen, setIsOpen } = this.props; // Access props here

    const handleClose = () => {
      setIsOpen(false);
    };

    return (
      <div className='add-user-modal-container'>
        
        <Dialog open={isOpen} fullWidth>
          <form onSubmit={this.handleSubmit}>
            <DialogTitle>Create User</DialogTitle>
            <DialogContent>

              <TextField
                autoFocus
                margin="dense"
                id="userName"
                name="userName"
                label="Username"
                type="text"
                fullWidth
                required
                variant="outlined"
                value={this.state.userName}
                onChange={this.handleChange}
              />

              <TextField
                autoFocus
                margin="dense"
                id="userPassword"
                name="userPassword"
                label="Password"
                type="password"
                fullWidth
                required
                variant="outlined"
                value={this.state.userPassword}
                onChange={this.handleChange}
              /><br/>

                <TextField
                  margin="dense"
                  id="userRole"
                  name="userRole" // Set the name attribute to match the state variable name
                  label="User Role"
                  select
                  value={this.state.userRole}
                  onChange={this.handleChange}
                  fullWidth
                  required
                  displayEmpty
                >
                   <MenuItem disabled value="">
                    <em>Select Role</em>
                   </MenuItem>
                  
                  {this.state.roles.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.role_name}
                    </MenuItem>
                  ))}
                </TextField>

             
            </DialogContent>
              <DialogActions sx={{padding:'30px'}}>
                <Button variant="contained" color='success' type='submit'>Submit</Button>
                <Button variant="contained" color="error" onClick={handleClose}>Cancel</Button>
              </DialogActions>
              </form>
          </Dialog>

      </div>
    )
  }
}

export default Add_User_Modal;
