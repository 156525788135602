import "./user_header.css";
import React, { useState } from 'react';

import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';

import AddUserModal from "../user-add-modal/Add_User_Modal";
import ExitDialog from "../../alert-dialogs/exit-dialogs/Exit_Dialog";

function User_header({ onSearch }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [isExitDialogOpen, setExitDialogOpen] = useState(false);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);

  const handleSearch = () => {
    // Call the onSearch prop with the search query
    onSearch(searchQuery);
  }

  // OPEN ADD CATEGORY MODAL//
  const handleOpenAddUserModal = () => {
    setIsAddUserModalOpen(true);
  };

  // Exit Dialog
  const openExitDialog = () => {
    setExitDialogOpen(true);
  };

  const closeExitDialog = () => {
    setExitDialogOpen(false);
  };

  return (
    <div className="user-header">
      <div className="user-header-search">
        <TextField
          id="outlined-start-adornment"
          sx={{ m: 1, width: '100%' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon onClick={handleSearch} sx={{cursor:'pointer'}} />
              </InputAdornment>
            ),
          }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      <div className="user-header-buttons">

        <div className="user-button">
            <Button variant="contained" color="success" size="large" fullWidth onClick={handleOpenAddUserModal} >
              New User
            </Button>
          </div>
          <AddUserModal isOpen={isAddUserModalOpen} setIsOpen={setIsAddUserModalOpen} />

        <div className="user-button">
          <Button variant="contained" color="error" fullWidth size="large" onClick={openExitDialog}>
            Exit
          </Button>

          <ExitDialog
            isOpen={isExitDialogOpen}
            onClose={closeExitDialog}
          />
        </div>
      </div>
    </div>
  )
}

export default User_header
