import "./report_header.css";
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';

import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

import ExitDialog from "../../alert-dialogs/exit-dialogs/Exit_Dialog";

function Report_Header({ setStartDate, setEndDate, setSelectedMethod }) {
  const [method, setMethod] = React.useState('');
  const [isExitDialogOpen, setExitDialogOpen] = useState(false);

  const handleChange = (event) => {
    setMethod(event.target.value);
  };

  const handleFilterClick = () => {
    // Call the filtering function in Report_Table component
    // and pass the filter criteria.
    setStartDate(document.getElementById('startDate').value);
    setEndDate(document.getElementById('endDate').value);
    setSelectedMethod(method);
  };

  // Exit Dialog
  const openExitDialog = () => {
    setExitDialogOpen(true);
  };

  const closeExitDialog = () => {
    setExitDialogOpen(false);
  };

  return (
    <div className="report-header">
      <div className="report-header-print">

        <div className="filter-date">
          <InputLabel align="center">Start Date</InputLabel>
          <TextField id="startDate" name="startDate" variant="outlined" type="date" fullWidth/>
        </div>

        <div className="filter-date">
          <InputLabel align="center">End Date</InputLabel>
          <TextField id="endDate" name="endDate" variant="outlined" type="date" fullWidth/>
        </div>

        <div className="filter-date">
          <InputLabel align="center">Select Method</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            fullWidth
            value={method}
            label="Method"
            required
            onChange={handleChange}
            displayEmpty
          >
            <MenuItem disabled value="">
              <em>Select Method</em>
            </MenuItem>
            <MenuItem value={'Cash'}>Cash</MenuItem>
            <MenuItem value={'Credit'}>Credit</MenuItem>
            <MenuItem value={'Cheque'}>Cheque</MenuItem>
          </Select>
        </div>

      </div>

      <div className="report-header-button">
        <div className="report-button">
          <Button variant="contained" color="success" fullWidth size="large" onClick={handleFilterClick}>
            Filter
          </Button>
        </div>

        <div className="report-button">
          <Button variant="contained" color="error" fullWidth size="large" onClick={openExitDialog}>
            Exit
          </Button>

          <ExitDialog
            isOpen={isExitDialogOpen}
            onClose={closeExitDialog}
          />
        </div>
      </div>

     
    </div>
  )
}

export default Report_Header
