import "./product_header.css";
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';

import AddProductModal from "../product-add-modal/Add_Product_Modal";
import ExitDialog from "../../alert-dialogs/exit-dialogs/Exit_Dialog";

function Product_header({ onSearch }) {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [isExitDialogOpen, setExitDialogOpen] = useState(false);
  const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);

  const handleSearch = () => {
    // Call the onSearch prop with the search query
    onSearch(searchQuery);
  }

  // OPEN ADD PRODUCT MODAL//
  const handleOpenAddProductModal = () => {
    setIsAddProductModalOpen(true);
  };

  // Exit Dialog
  const openExitDialog = () => {
    setExitDialogOpen(true);
  };

  const closeExitDialog = () => {
    setExitDialogOpen(false);
  };

  return (
    <div className='product-header'>

      <div className="product-header-search">
        <TextField
            id="outlined-start-adornment"
            sx={{ m: 1, width: '100%' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon onClick={handleSearch} sx={{cursor:'pointer'}} />
                </InputAdornment>
              ),
            }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
      </div>

      <div className="product-header-buttons">

        <div className="header-button">
          <Button variant="contained" color="success" size="large" fullWidth onClick={handleOpenAddProductModal}>
            New Product
          </Button>
        </div>
        
        <AddProductModal isOpen={isAddProductModalOpen} setIsOpen={setIsAddProductModalOpen} />

        <div className="header-button">
          <Button variant="contained" color="success" size="large" fullWidth  onClick={() => navigate('/product-category')}>
            Categories
          </Button>
        </div>
        <div className="header-button">
          <Button variant="contained" color="success" size="large" fullWidth onClick={() => navigate('/product-brand')}>
            Brands
          </Button>
        </div>
        <div className="header-button">
          <Button variant="contained" color="error" size="large" fullWidth onClick={openExitDialog}>
            Exit
          </Button>
          <ExitDialog
            isOpen={isExitDialogOpen}
            onClose={closeExitDialog}
          />
        </div>

      </div>
    </div>
  )
}

export default Product_header;
