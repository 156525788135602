import "./customer_discount.css";
import React from 'react';

import Header from "../../components/header/Header";
import DiscountHeader from "../../components/customer-component/discount-header/Discount_Header"
import DiscountTable from "../../components/customer-component/discount-table/Discount_Table"

function Customer_Discount() {
  return (
    <div className="customer-discount">
      <Header/>
      <div className="discount-table-header">
        <DiscountHeader/>
      </div>

      <div className="discount-table-content">
        <DiscountTable/>
      </div>
    </div>
  )
}

export default Customer_Discount
