import "./report_table.css";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from "../../../base_url";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

// TABLE IMPORTS
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'gray',
    color: theme.palette.common.white,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function Report_Table({ startDate, endDate, selectedMethod }) {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const url = BASE_URL + 'Get_Transaction.php';
    axios.get(url)
      .then(response => response.data)
      .then(data => {
        setTransactions(data); // Fixed the variable name here
      });
  }, []);

  const filterTransactions = () => {
    // Filter the transactions based on the selected criteria (startDate, endDate, and paymentMethod).
    const filteredTransactions = transactions.filter((item) => {
      const transactionDate = new Date(item.transaction_date);
      const startDateFilter = startDate ? new Date(startDate) : null;
      const endDateFilter = endDate ? new Date(endDate) : null;
      const methodFilter = selectedMethod !== '' ? selectedMethod : null;

      // Apply filters
      const dateFilter =
        (!startDateFilter || transactionDate >= startDateFilter) &&
        (!endDateFilter || transactionDate <= endDateFilter);
      const methodFilterCondition =
        !methodFilter || item.method_name === methodFilter;

      return dateFilter && methodFilterCondition;
    });

    return filteredTransactions;
  };

  const downloadAsPDF = () => {
    const pdf = new jsPDF();
    const tableData = [];
  
    // Create an array with the table headers
    const headers = [
      'ID',
      'Customer Name',
      'Transaction Date',
      'Amount Purchased',
      'Amount Paid',
      'Payment Method',
    ];
  
    // Add headers to tableData
    tableData.push(headers);
  
    // Add rows from the filtered transactions
    const filteredTransactions = filterTransactions();
    filteredTransactions.forEach((item) => {
      tableData.push([
        item.id,
        item.customer_name,
        item.transaction_date,
        item.total_amount ? parseFloat(item.total_amount).toLocaleString('en-US') : '',
        item.amount_paid ? parseFloat(item.amount_paid).toLocaleString('en-US') : '',
        item.method_name,
      ]);
    });
  
    // AutoTable is used to generate the table in the PDF
    pdf.autoTable({
      head: [headers],
      body: tableData,
    });
  
    pdf.save('report.pdf');
  };

  const downloadAsExcel = () => {
    const tableData = [];
    
    // Create an array with the table headers
    const headers = [
      'ID',
      'Customer Name',
      'Transaction Date',
      'Amount Purchased',
      'Amount Paid',
      'Payment Method',
    ];
  
    // Add headers to tableData
    tableData.push(headers);
  
    // Add rows from the filtered transactions
    const filteredTransactions = filterTransactions();
    filteredTransactions.forEach((item) => {
      tableData.push([
        item.id,
        item.customer_name,
        item.transaction_date,
        item.total_amount ? parseFloat(item.total_amount).toLocaleString('en-US') : '',
        item.amount_paid ? parseFloat(item.amount_paid).toLocaleString('en-US') : '',
        item.method_name,
      ]);
    });
  
    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(tableData);
    
    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Transaction Data');
    
    // Save the Excel file
    XLSX.writeFile(wb, 'report.xlsx');
  };

  return (
    <div className="report-transaction-table">
      <div className="table-button" align="center">
        <Button variant="contained" size="large" color="info" fullWidth onClick={downloadAsPDF}>Download as PDF</Button>&nbsp;
        <Button variant="contained" size="large" color="info" fullWidth onClick={downloadAsExcel}>Download as Excel</Button>
      </div><br/>
    
      <TableContainer component={Paper} sx={{ maxHeight: 680 }}>
        <Table stickyHeader sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell align="center">Customer Name</StyledTableCell>
              <StyledTableCell align="center">Transaction Date</StyledTableCell>
              <StyledTableCell align="center">Amount Purchased</StyledTableCell>
              <StyledTableCell align="center">Amount Paid</StyledTableCell>
              <StyledTableCell align="center">Payment Method</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>

          {filterTransactions().map((item) => (
              <StyledTableRow key={item.id}>
                <StyledTableCell component="th" scope="row">{item.id}</StyledTableCell>
                <StyledTableCell align="center">{item.customer_name}</StyledTableCell>
                <StyledTableCell align="center">{item.transaction_date}</StyledTableCell>
                <StyledTableCell align="center">
                  {item.total_amount ? parseFloat(item.total_amount).toLocaleString('en-US') : ''}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {item.amount_paid ? parseFloat(item.amount_paid).toLocaleString('en-US') : ''}
                </StyledTableCell>
                <StyledTableCell align="center">{item.method_name}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default Report_Table
