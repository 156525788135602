import "./small_widgets.css";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from "../../../base_url";

import { ReactComponent as ProductIcon }from "../../../assets/icons/shopping-cart.svg"
import { ReactComponent as CustomerIcon }from "../../../assets/icons/users.svg"
import { ReactComponent as DollarIcon }from "../../../assets/icons/dollar-sign.svg"

function Small_widgets() {
  const [totalProducts, setTotalProducts] = useState(null);
  const [totalCustomers, setTotalCustomers] = useState(null);
  const [totalTransaction, setTotalTransaction] = useState(null);

  useEffect(() => {
    // Make an Axios GET request to your PHP API endpoint
    const url = BASE_URL + 'Get_Sales_Widgets.php';
    axios.get(url)
      .then(response => {
        console.log('Response Data:', response.data);
        const { get_total_transactions  } = response.data;
        const { get_total_products } = response.data;
        const { get_total_customers } = response.data;
        
        setTotalTransaction(get_total_transactions);
        setTotalProducts(get_total_products);
        setTotalCustomers(get_total_customers);
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
      });
  }, []);
  
  return (
    <div className='small-widgets'>

      <div className='total total-products'>
        <div className='widget-icon'>
          <ProductIcon fontSize='large' />
        </div>
        <h2>{totalProducts || 0}</h2>
        <h4>Total Products</h4>
      </div>

      <div className='total total-resellers'>
        <div className='widget-icon'>
          <CustomerIcon />
        </div>
        <h2>{totalCustomers || 0}</h2>
        <h4>Total Customers</h4>
      </div>

      <div className='total sales-this-month'>
        <div className='widget-icon'>
          <DollarIcon />
        </div>
        <h2>{totalTransaction || 0}</h2>
        <h4>Total Transactions</h4>
      </div>

  </div>
  )
}

export default Small_widgets
