import "./brand_table.css";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from "../../../base_url";
import EditBrandModal from "../brand-edit-modal/Edit_Brand_Modal";

// TABLE IMPORTS
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'gray',
    color: theme.palette.common.white,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function Brand_table() {
  const [brands, setBrands] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);

  useEffect(() => {
    const url = BASE_URL + 'Get_Brand.php';
    axios.get(url)
      .then(response => response.data)
      .then(data => {
        setBrands(data); // Fixed the variable name here
      });
  }, []);

  // Function to open the Edit Modal
  const openEditModal = (brands) => {
    setSelectedBrand(brands);
    setIsEditModalOpen(true);
  };

  // Function to close the Edit Modal
  const closeEditModal = () => {
    setSelectedBrand(null);
    setIsEditModalOpen(false);
  };

  const deleteBrand = (id, event) => {
    event.preventDefault();
    if (window.confirm("Are you sure want to delete?")) {
      axios({
        method: 'POST',
        url: BASE_URL + 'Get_Brand.php/?delete=' + id
      })
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          alert("Brand deleted successfully");
          window.location.reload();
        }
      })
      .catch(function (response) {
        alert("Brand deleted successfully");
        window.location.reload();
        console.log(response);
      });
    }
  };
  if (brands.length > 0) {
  return (
    <div className="brand-table">
      <TableContainer component={Paper} sx={{ maxHeight: 680 }}>
        <Table stickyHeader sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell hidden>ID</StyledTableCell>
              <StyledTableCell align="center">Brand Name</StyledTableCell>
              <StyledTableCell align="center">Category</StyledTableCell>
              <StyledTableCell align="center" width="200">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {brands.map((item) => (
              <StyledTableRow key={item.id}>
                <StyledTableCell component="th" hidden scope="row">{item.id}</StyledTableCell>
                <StyledTableCell align="center">{item.brand_name}</StyledTableCell>
                <StyledTableCell align="center">{item.category_name}</StyledTableCell>
                <StyledTableCell align="center">
                  <Button variant="contained" color="success" fullWidth onClick={() => openEditModal(item)}>
                    Edit
                  </Button>
                  &nbsp;
                  <Button variant="contained" color="error" fullWidth onClick={(event) => deleteBrand(item.id, event)}>
                    Delete
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Render the Edit_Product_Modal */}
      {isEditModalOpen && (
        <EditBrandModal
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
          selectedBrand={selectedBrand}
        />
      )}
    </div>
  )
}
else {
  return (
    <div id="containers">
      No record found
    </div>
  );
}
}

export default Brand_table
