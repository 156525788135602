import "./dashboard.css";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Widget from "../../components/dashboard-components/small-widgets/Small_widgets";
import Chart from "../../components/dashboard-components/dashboard-chart/Dash_chart";
import Buttons from "../../components/dashboard-components/dashboard-buttons/Dash_buttons";

function Dashboard() {

  const navigate = useNavigate();
  
  const [sessionID, setSessionID] = useState(''); // Initialize sessionID state
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    const sessionIDCookie = document.cookie.split(';').find(cookie => cookie.startsWith('sessionID='));
    const userRoleCookie = document.cookie.split(';').find(cookie => cookie.startsWith(' userRole='));
    if (!sessionIDCookie) {
      navigate('/');
    } else {
      const sessionIDValue = sessionIDCookie.split('=')[1]; // Extract sessionID
      setSessionID(sessionIDValue); // Set the sessionID state

      const userRoleValue = userRoleCookie ? userRoleCookie.split('=')[1] : '';
      setUserRole(userRoleValue);
    }
  }, [navigate]);

  return (
    <div className='dashboard'>
      <Header/>
      < Widget/>
        <div className="chart-widget">
        <label hidden>Session ID: {sessionID}</label>
          < Chart/>
        </div>

        <div className="button-widget">
          <Buttons userRole={userRole}/>
        </div>
        
      <Footer userRole={userRole}/>
    </div>
  )
}

export default Dashboard
