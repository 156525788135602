import "./product_cart_modal.css";
import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { BASE_URL } from '../../../base_url';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';

import ReceiptModal from "../receipt-modal/Receipt_Modal";

function Product_Cart_Modal ({ 
  isOpen, 
  onClose, 
  cart,
  selectedCustomerName,
  total,
  selectedpaymentMethod,
  cashReceived,
  selectedCustomerType,
  change }) {

    const [isReceiptDialogOpen, setReceiptDialogOpen] = useState(false); // Use useState
    const [customerName, setCustomerName] = useState('');
    const [customerType, setCustomerType] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');

    useEffect(() => {
      if (selectedCustomerName && selectedCustomerType) {
        // Fetch customer name, type, and payment method from the server
        axios.get(`${BASE_URL}Get_Receipt_Data.php?selectedCustomerName=${selectedCustomerName}&selectedCustomerType=${selectedCustomerType}&selectedpaymentMethod=${selectedpaymentMethod}`)
          .then((response) => {
            if (response.data.success) {
              setCustomerName(response.data.data.customer_name);
              setCustomerType(response.data.data.discount_name);
              // Assuming you have a field in your PHP response for payment method
              setPaymentMethod(response.data.data.method_name);
            } else {
              console.error('Error fetching customer data:', response.data.error);
            }
          })
          .catch((error) => {
            console.error('Error fetching customer data:', error);
          });
      }
    }, [selectedCustomerName, selectedCustomerType, selectedpaymentMethod]);

    // Callback function to close the Product Modal
    const closeProductModal = () => {
      onClose();
    };

    // Receipt Dialog
    const openReceiptDialog = () => {
      setReceiptDialogOpen(true);
    };

    const closeReceiptDialog = () => {
      setReceiptDialogOpen(false);
    };


    const handlePay = () => {
      const confirmed = window.confirm('Are you sure you want to submit the transaction?');
  
      if (confirmed) {
        const transactionData = {
          customer_id: selectedCustomerName,
          total_amount: total,
          payment_method: selectedpaymentMethod,
          cash_received: cashReceived,
          cart: cart,
        };
  
        axios.post(BASE_URL + 'Save_Transaction.php', transactionData)
          .then((response) => {
            if (response.data.success) {
              closeProductModal(); // Close the Product Modal on success
              openReceiptDialog(); // Open Receipt Modal on success
            } else {
              window.alert('Transaction failed.');
            }
          })
          .catch((error) => {
            console.error('Error inserting transaction data:', error);
            window.alert('Error submitting the transaction. Please try again.');
          });
      } else {
        console.log('Transaction submission canceled.');
      };
    };

  const tableContent = (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Item Name</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Unit</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cart.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.product_name}</TableCell>
              <TableCell>{item.quantity}</TableCell>
              <TableCell>{item.unit}</TableCell>
              <TableCell>{item.product_price}</TableCell>
              <TableCell>{item.product_price * item.quantity}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <div>
       <Dialog open={isOpen} onClose={onClose} fullWidth>
        <DialogTitle>Cart Items</DialogTitle>
          <DialogContent>
            <p hidden>Customer Type: {customerType || 'N/A'}</p>
            <p hidden>Customer name: {customerName || 'N/A'}</p>
            <p hidden>Payment Method: {paymentMethod || 'N/A'}</p>
            
            {tableContent}<br/>
            <hr/>
            <div className='table-footer'>
              <p>Total: P{total}.00</p>
              <p>Cash Received: P{cashReceived}.00</p>
              <p>Change: P{change}.00</p>
            </div>
            
          </DialogContent>
          <DialogActions>  
            <Button onClick={handlePay}  disabled={cart.length === 0 || !selectedCustomerType || !selectedCustomerName} color="primary">
              Pay
            </Button>
            <Button onClick={onClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {isReceiptDialogOpen && (
            <ReceiptModal 
              isOpen={isReceiptDialogOpen} 
              onClose={closeReceiptDialog}
              cart={cart}
              selectedCustomerName={customerName}
              total={total}
              selectedpaymentMethod={paymentMethod}
              cashReceived={cashReceived}
              change={change}
              selectedCustomerType= {customerType} />
          )}
    </div>
  )
}

export default Product_Cart_Modal
