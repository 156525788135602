import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

function Receipt_Modal({
  isOpen, 
  onClose,
  cart,
  selectedCustomerName,
  total,
  selectedpaymentMethod,
  cashReceived,
  selectedCustomerType,
  change}) {

    const handleClose = () => {
      onClose();
      window.location.reload();
    };

    const handlePrintReceipt = () => {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString();
      const formattedTime = currentDate.toLocaleTimeString();
  
      // Create a string containing the receipt details
      let receiptContent = `
        <html>
        <head>
          <title>Receipt</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              font-size: 11px;
            }
            .header {
              text-align: center;
              font-size: 15px;
              line-height:2px;
            }

            .header p{
              font-size: 11px;
            }
            .address {
              text-align: left;
              display: flex;
              justify-content: space-between;
              margin-bottom: 10px;
            }

            .cart {
              display: flex;
              justify-content: space-between;
              line-height:2px;
            }
            .cart-item {
              flex-grow: 1;
            }
            .cart-total {
              text-align: right;
            }
            .footer{
              width:100%;
              text-align:right;
            }

            .footer2{
              width:100%;
              text-align:center;
            }
        
          </style>
        </head>
        <body>
          <div class="header">
            <h4>ROGUEL'S TRADING</h4>
            <p> Indang Cavite
          </div>
          <br>
          <div class="address">
            <div class="add1">
              <p>${formattedTime} | ${formattedDate}</p>
              <p>Customer's Name: ${selectedCustomerName}</p>
              <p>Type: ${selectedCustomerType}</p>
              <p>Receipt No.:</p>
            </div>
          </div>
          <br>
          `;
  
      cart.forEach((item) => {
        receiptContent += `
          <div class="cart">
            <div class="cart-item">
            <p>${item.product_name} @${item.product_price} x${item.quantity} --${item.unit}</p>
            </div>
            <div class="cart-total">
              <p>${item.product_price * item.quantity}.00</p>
            </div>
          </div>
          `;
        });
    
        receiptContent += `

          <br>
          <div class="footer">
            <hr>
            <p>Subtotal: ${total}.00</p>
            <p>Total: ${total}.00</p>
            <hr>
            <p>Cash: ${cashReceived}.00</p>
            <p>Change: ${change}.00</p>
            <p>Payment Type: ${selectedpaymentMethod}</p>
          </div>
          <br>
          <div class="footer2">
          <p>Thank you!</p>
          <p>Please come again!</p>
          </div>
          
        </body>
        </html>
      `;
  
      // Create a new window to display the receipt
      const receiptWindow = window.open('', '_blank');
      receiptWindow.document.open();
      receiptWindow.document.write(receiptContent);
      receiptWindow.document.close();
  
      // Print the receipt
      receiptWindow.print();
  
      // Close the receipt window after printing
      receiptWindow.close();
    };

  return (
    <div className='report-modal'>
      <Dialog open={isOpen} onClose={onClose} fullWidth>
        <Alert severity="success">
          <AlertTitle>Your Transaction is Successful!</AlertTitle>
        </Alert>
        <DialogTitle></DialogTitle>
          <DialogContent>
            <h5>Do you want to print a receipt?</h5>
          </DialogContent>
          <DialogActions>  
            <Button variant="contained" color="success" onClick={() => {
              handlePrintReceipt();
              onClose();
            }}>
              Yes
            </Button>
            <Button onClick={handleClose} variant="contained" color="error">
              No
            </Button>
          </DialogActions>
        </Dialog>
    </div>
  )
}

export default Receipt_Modal
