import "./customer_header.css";
import React, { useState } from 'react';

import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';

import AddCustomerModal from "../customer-add-modal/Add_Customer_modal";
import ExitDialog from "../../alert-dialogs/exit-dialogs/Exit_Dialog";

function Customer_header({ onSearch }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [isExitDialogOpen, setExitDialogOpen] = useState(false);
  const [isAddCustomerModalOpen, setIsAddCustomerModalOpen] = useState(false);

  const handleSearch = () => {
    // Call the onSearch prop with the search query
    onSearch(searchQuery);
  }

  // OPEN ADD CUSTOMER MODAL//
  const handleOpenAddCustomerModal = () => {
    setIsAddCustomerModalOpen(true);
  };

  // Exit Dialog
  const openExitDialog = () => {
    setExitDialogOpen(true);
  };

  const closeExitDialog = () => {
    setExitDialogOpen(false);
  };

  return (
    <div className="customer-header">
       <div className="customer-header-search">
        <TextField
          id="outlined-start-adornment"
          sx={{ m: 1, width: '100%' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon onClick={handleSearch} sx={{cursor:'pointer'}} />
              </InputAdornment>
            ),
          }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
        <div className="customer-header-buttons">

          <div className="customer-button">
            <Button variant="contained" size="large" color="success" fullWidth onClick={handleOpenAddCustomerModal} >
              New Customer
            </Button>
          </div>

          <AddCustomerModal isOpen={isAddCustomerModalOpen} setIsOpen={setIsAddCustomerModalOpen} />

            <div className="customer-button">
              <Button variant="contained" color="error" fullWidth size="large" onClick={openExitDialog}>
                Exit
              </Button>
              <ExitDialog
                isOpen={isExitDialogOpen}
                onClose={closeExitDialog}
              />
            </div>
          </div>
    </div>
  )
}

export default Customer_header
