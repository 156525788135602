import React from 'react';
import { useNavigate } from 'react-router-dom';

//Dialog Imports//
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

function Exit_Dialog({isOpen, onClose}) {
  const navigate = useNavigate();

  return (
    <div className='exit-dialog'>
      <Dialog open={isOpen} fullWidth>  
        <DialogTitle>Confirm Exit</DialogTitle>
            <DialogContent>
              Are you sure you want to exit?
            </DialogContent>
            <DialogActions sx={{padding:'30px'}}>
              <Button variant="contained" color="success" onClick={() => navigate('/dashboard')} >Yes</Button>
              <Button variant="contained" color="error" onClick={onClose}>Cancel</Button>
              </DialogActions>
      </Dialog>
    </div>
  )
}

export default Exit_Dialog
