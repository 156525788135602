import "./category_header.css";
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';

import AddCategoryModal from "../category-add-modal/Add_Category_Modal";

function Category_header() {
  const navigate = useNavigate();
  const [isAddCategoryModalOpen, setIsAddCategoryModalOpen] = useState(false);

  // OPEN ADD CATEGORY MODAL//
  const handleOpenAddCategoryModal = () => {
    setIsAddCategoryModalOpen(true);
  };

  return (
    <div className='category-header'>

      <div className="category-header-search">
        <TextField
            id="outlined-start-adornment"
            sx={{ m: 1, width: '100%' }}
            InputProps={{
              endAdornment: <InputAdornment position="end"><SearchIcon/></InputAdornment>,
            }}
          />
      </div>

      <div className="category-header-buttons">

        <div className="category-button">
          <Button variant="contained" size="large" color="success" fullWidth onClick={handleOpenAddCategoryModal} >
            New Category
          </Button>
        </div>

        <AddCategoryModal isOpen={isAddCategoryModalOpen} setIsOpen={setIsAddCategoryModalOpen} />

        <div className="category-button">
          <Button variant="contained" color="error" size="large" onClick={() => navigate('/products')} fullWidth>
            Exit
          </Button>
        </div>

      </div>
       
    </div>
  )
}

export default Category_header
