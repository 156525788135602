import "./dash_buttons.css";
import { useNavigate } from 'react-router-dom';
import React from 'react';

import Button from '@mui/material/Button';
import { ReactComponent as DollarIcon }from "../../../assets/icons/dollar-sign-small.svg";
import { ReactComponent as InventoryIcon }from "../../../assets/icons/check-square.svg";
import { ReactComponent as CustomerIcon }from "../../../assets/icons/users-small.svg";
import { ReactComponent as TransactionIcon }from "../../../assets/icons/repeat-small.svg";
import { ReactComponent as ProductIcon }from "../../../assets/icons/shopping-bag.svg";

function Dash_buttons({ userRole }) {

  const navigate = useNavigate();

  return (
    <div className='dashboard-buttons'>

      <div className='button'>
        <Button variant="contained" color="success" startIcon={<DollarIcon/>} 
        sx={{padding:'20px', fontSize:'17px', fontFamily:'Cascadia Code', borderRadius:'7px'}}
        onClick={() => navigate('/cash-register')} fullWidth>
          Cash Register
        </Button>
      </div>

      <div className='button'>
        <Button variant="contained" color="success" startIcon={<ProductIcon/>}
          sx={{padding:'20px', fontSize:'17px', fontFamily:'Cascadia Code', borderRadius:'7px'}}
        onClick={() => navigate('/products')}  disabled={userRole !== '1'} fullWidth>
          Products
        </Button>
      </div>

      <div className='button'>
        <Button variant="contained" color="success" startIcon={<InventoryIcon/>}
          sx={{padding:'20px', fontSize:'17px', fontFamily:'Cascadia Code', borderRadius:'7px'}} 
          onClick={() => navigate('/inventory')} disabled={userRole !== '1'} fullWidth>
          Inventory
        </Button>
      </div>

      <div className='button'>
        <Button variant="contained" color="success" startIcon={<TransactionIcon/>}
          sx={{padding:'20px', fontSize:'17px', fontFamily:'Cascadia Code', borderRadius:'7px'}}  
          onClick={() => navigate('/transactions')} fullWidth>
            Transactions
        </Button>
      </div>

      <div className='button'>
        <Button variant="contained" color="success" startIcon={<CustomerIcon />}
          sx={{padding:'20px', fontSize:'17px', fontFamily:'Cascadia Code', borderRadius:'7px'}} 
          onClick={() => navigate('/customers')} fullWidth>
          Customers
        </Button>
      </div>

    </div>
  )
}

export default Dash_buttons;
