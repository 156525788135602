import "./customer.css";
import React, { useState } from 'react';

import Header from "../../components/header/Header";
import CustomerHeader from "../../components/customer-component/customer-header/Customer_header";
import CustomerTable from "../../components/customer-component/customer-table/Customer_table";

function Customer() {
  const [searchQuery, setSearchQuery] = useState('');
  return (
    <div className='customers'>
      <Header/>
      
      <div className="customer-table-header">
        <CustomerHeader onSearch={setSearchQuery}/>
      </div>

      <div className="customer-table-content">
        <CustomerTable searchQuery={searchQuery}/>
      </div>

    </div>
  )
}

export default Customer
