import "./product_cards.css";
import React from 'react';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import axios from 'axios';
import { BASE_URL } from "../../../base_url";

class Product_cards extends React.Component {

  constructor(props){
    super(props);
    this.state = { products: [], inventories: [], customerType: 0, checkedItems: {}, snackbarOpen: false, snackbarMessage: "", };
  }

  componentDidMount() {
    const url = BASE_URL + 'Get_Product.php';
    axios.get(url)
      .then(response => response.data)
      .then((data) => {
        // Initialize checked state for each product to false
        const checkedItems = {};
        const updatedProducts = data.map((item) => {
          checkedItems[item.id] = false;
          if (item.per_item === 1) {
            item.unit = 'item(s)';
          } else {
            item.unit = 'sack';
          }
          return item;
        });
        
        this.setState({ products: updatedProducts, checkedItems });
        console.log(this.state.products);
      });

      const inventoryUrl = BASE_URL + 'Get_Inventory.php';
      axios.get(inventoryUrl)
        .then(response => response.data)
        .then(inventories => {
          this.setState({ inventories }); // Set the inventories state
        })
        .catch(error => {
          console.error("Error fetching inventories:", error);
        });

  }
    
  handleCheckboxChange = (itemId) => {
    this.props.handleCheckboxChange(itemId);
  
    this.setState((prevState) => {
      const updatedCheckedItems = { ...prevState.checkedItems };
      updatedCheckedItems[itemId] = !updatedCheckedItems[itemId];
  
      const updatedProducts = prevState.products.map((product) => {
        if (product.id === itemId) {
          if (updatedCheckedItems[itemId]) {
            if(product.per_item ===1){
              product.unit = 'piece(s)';
              // Update retail_stocks based on wholesale_stocks (you can modify this logic as needed)
              product.retail_stocks = product.wholesale_stocks;
            }else{ 
              product.unit = 'kg';
              // Update retail_stocks based on wholesale_stocks (you can modify this logic as needed)
              product.retail_stocks = product.wholesale_stocks;}
          } else {
            product.unit = 'sack';
            // Set retail_stocks back to the default value (wholesale_stocks)
            product.retail_stocks = product.wholesale_stocks;
          }
        }
        return product;
      });
  
      return { checkedItems: updatedCheckedItems, products: updatedProducts };
    });
  };
  
  handleSnackbarOpen = (message) => {
    this.setState({
      snackbarOpen: true,
      snackbarMessage: message,
    });
  };

  handleSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  render() {
    const { searchQuery } = this.props;

    // Filter the products based on the searchQuery
    const filteredProducts = this.state.products.filter((item) =>
      item.product_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.category_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const productFound = filteredProducts; // Use filteredProducts here, not this.state.products
    const { customerType } = this.props;

    if (productFound.length > 0) { // Check if there are filtered products
  return (
    <div className="product-cards-container">

      <div className="product-cards-item">
      {filteredProducts.map((item) => {
        return(
          <Card sx={{ maxWidth: 345, width:'250px',boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
             <CardHeader title={item.product_name} />
            <CardContent key={item.id}>
              <Typography variant="h6" color="text.secondary" align="center">
              {item.category_name}
              </Typography>
              <Typography variant="body2" color="text.secondary" align="center">
              {item.brand_name}
              </Typography>

              {this.state.inventories.map(items => {
                if (items.product_id === item.id) {
                  return (
                    <Typography key={items.id} variant="body2" color="text.secondary" align="left">
                      Stocks: {this.state.checkedItems[item.id] ? items.retail_stocks : items.wholesale_stocks}
                    </Typography>
                  );
                }
                return null;
              })}
                  
                  <Typography variant="h6" color="text.secondary" align="left">
                    P
                    {customerType === 3 && this.state.checkedItems[item.id]
                      ? `${item.product_price}.00`
                      : customerType === 3
                      ? `${item.walkin_customer_price}.00`
                      : this.state.checkedItems[item.id]
                      ? `${item.wholesale_price_kilo}.00`
                      : customerType === 2
                      ? `${item.subdealer_price}.00`
                      : customerType === 1
                      ? `${item.reg_customer_price}.00`
                      : `${item.product_price}.00`}
                  </Typography>

                  <Typography hidden gutterBottom variant="p" component="div">
                    {item.per_item}
                  </Typography>
              
              <FormControlLabel
                label="Price per piece / kilo" control={
                  <Checkbox
                    checked={this.state.checkedItems[item.id]}
                    onChange={() => this.handleCheckboxChange(item.id)}
                  />
                }
              />
              
            </CardContent>
           
            <CardActions>

            {this.state.inventories.map(items => {
            if (items.product_id === item.id) {
              return (

              <Button
                size="small"
                color="success"
                onClick={() => {
                  let price;
                  if (customerType === 3 && this.state.checkedItems[item.id]) {
                    // When customerType is 3 and checkbox is checked, use item.product_price
                    price = item.product_price;
                  } else if (customerType === 3) {
                    // When customerType is 3 but checkbox is not checked, use item.reg_customer_price
                    price = item.walkin_customer_price;
                  } else {
                    // For other customerType values, follow your existing logic
                    if (this.state.checkedItems[item.id]) {
                      price = item.wholesale_price_kilo;
                    } else if (customerType === 2) {
                      price = item.subdealer_price;
                    } else if (customerType === 1) {
                      price = item.reg_customer_price;
                    } else {
                      price = item.product_price;
                    }
                  }
                  this.props.addToCart(item, customerType, price, this.state.checkedItems[item.id]);
                  const message = "Item added to cart";
                  this.handleSnackbarOpen(message);
                }}
              >
              Add to cart
            </Button>
                );
              }
              return null;
            })}
            </CardActions>
          </Card>
        )})}
      </div> 
      {/* Snackbar component */}
      <Snackbar
        open={this.state.snackbarOpen}
        autoHideDuration={2000}
        onClose={this.handleSnackbarClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={this.handleSnackbarClose}
          severity="success"
        >
          {this.state.snackbarMessage}
        </MuiAlert>
      </Snackbar>
      
    </div>
 )
}
else {
  return (
      <div id="containers">
          No record found
      </div>
  )
}
}
}

export default Product_cards