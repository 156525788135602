import "./cash_register.css";
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../base_url';

import Header from '../../components/header/Header';
import ProductCards from '../../components/product-components/product-cards/Product_cards';
import ProductCardsSearch from '../../components/product-components/search-product-cards/Search_Product'
import CartForm from '../../components/product-components/product-cart/Product_Cart'; // Import the CartForm component

function Cash_Register() {
  const navigate = useNavigate();
  const [cart, setCart] = useState([]);
  const [discount, setDiscount] = useState([]);
  const [customerType, setCustomerType] = useState('');
  const [checkedItems, setCheckedItems] = useState({}); 
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    // Fetch discount data from the server when the component mounts
    const url = BASE_URL + 'Get_Discount.php';
    axios
      .get(url)
      .then((response) => {
        setDiscount(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error('Error fetching discount data:', error);
      });
  }, []);

  const handleExitClick = () => {
    const shouldExit = window.confirm('Are you sure you want to exit?');
    if (shouldExit) {
      navigate('/dashboard');
      console.log('Exiting...');
    }
  };

  const addToCart = (product, selectedCustomerType, price, isCheckboxChecked) => {
    const availableStocks = product.product_stocks;
  
    // Check if the selectedCustomerType is equal to 3
    if (selectedCustomerType === 3) {
      // If it is, check if the product is already in the cart
      const existingProduct = cart.find((item) => item.id === product.id);
  
      if (existingProduct) {
        // If the product is already in the cart, calculate the new quantity
        const newQuantity = existingProduct.quantity + 1;
  
        // Update the cart with the increased quantity and updated price
        setCart((prevCart) =>
          prevCart.map((item) =>
            item.id === product.id
              ? { ...item, quantity: newQuantity, product_price: price }
              : item
          )
        );
      } else {
        // If the product is not in the cart, add it with a quantity of 1
        setCart([...cart, { ...product, quantity: 1, product_price: price }]);
      }
    } else {
      // For other customer types, follow the original logic
      const existingProductIndex = cart.findIndex((item) => item.id === product.id);

      if (existingProductIndex !== -1) {
        const updatedCart = [...cart];
        updatedCart[existingProductIndex].quantity += 1;
      
        if (
          customerType === 1 || // For customerType 1
          customerType === 2 // For customerType 2
        ) {
          // Allow increasing quantity without checkbox for customerType 1 and 2
          setCart(updatedCart);
        } else if (isCheckboxChecked || updatedCart[existingProductIndex].quantity <= availableStocks) {
          // For other customer types, consider the checkbox and available stocks
          setCart(updatedCart);
        }
      } else {
        if (
          customerType === 1 || // For customerType 1
          customerType === 2 || // For customerType 2
          isCheckboxChecked || // When the checkbox is checked
          availableStocks > 0 // When there are available stocks
        ) {
          setCart([...cart, { ...product, quantity: 1, product_price: price }]);
        }
      }
    }
  };
  
  const handleCheckboxChange = (itemId) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [itemId]: !prevCheckedItems[itemId],
    }));
  };

  const calculateTotal = () => {
    return cart.reduce(
      (total, item) => total + item.product_price * item.quantity,
      0
    );
  };

  return (
    <div className="cash-register">
      <Header />

      <div className='cart-product-carts'>
      <CartForm
        cart={cart}
        setCart={setCart}
        discount={discount}
        customerType={customerType}
        setCustomerType={setCustomerType}
        calculateTotal={calculateTotal}
        handleExitClick={handleExitClick}
        addToCart={addToCart}
        checkedItems={checkedItems}
      />
      </div>

      <div className="cart-product-cards">
        <ProductCardsSearch onSearch={setSearchQuery}/>
        <ProductCards addToCart={addToCart} customerType={customerType} handleCheckboxChange={handleCheckboxChange} searchQuery={searchQuery} />
      </div>

    </div>
  );
}

export default Cash_Register;
