import "./add_product_modal.css";
import React from 'react';

//Dialog Imports//
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import MenuItem from '@mui/material/MenuItem';

import axios from 'axios';
import { BASE_URL } from "../../../base_url";

class Add_Product_Modal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      productId: '',
      productCode: '',
      productName: '',
      productCategory: '',
      productBrand: '',
      productPrice: '',
      subDealerPrice: '',
      regCustomerPrice: '',
      walkinCustomerPrice:'',
      categories: [],
      brands: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  componentDidMount() {
    this.fetchCategories();
  }

  fetchCategories() {
    const url = BASE_URL + 'Get_Category.php';
    axios.get(url).then(response => response.data)
      .then((data) => {
        this.setState({ categories: data });
        console.log(this.state.categories);
      });
  }

  fetchBrandsByCategory(categoryId) {
    const url = BASE_URL + `Get_Brand.php?category_id=${categoryId}`;
    axios.get(url).then(response => response.data)
      .then((data) => {
        this.setState({ brands: data });
        console.log(this.state.brands);
      });
  }

  handleSubmit(event) {
    event.preventDefault();
  
    let formData = new FormData();
    formData.append('productCode', this.state.productCode);
    formData.append('productName', this.state.productName);
    formData.append('productPrice', this.state.productPrice);
    formData.append('wholesalePrice', this.state.wholesalePrice);
    formData.append('subDealerPrice', this.state.subDealerPrice);
    formData.append('regCustomerPrice', this.state.regCustomerPrice);
    formData.append('walkinCustomerPrice', this.state.walkinCustomerPrice);
    formData.append('productCategory', this.state.productCategory);
    formData.append('productBrand', this.state.productBrand);
  
    axios({
      method: 'POST',
      url: BASE_URL + 'Add_Product.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
    })
      .then((response) => {
        // Handle success
        console.log(response);
        alert("Product added successfully");
        window.location.reload();
      })
      .catch((error) => {
        // Handle error
        console.log(error);
      });
  }

  handleChange(event) {
    const { name, value } = event.target;

    if (name === 'productCategory') {
      this.setState({
        productCategory: value,
        productBrand: '', // Clear the selected brand when the category changes
      });

      if (value) {
        this.fetchBrandsByCategory(value); // Fetch brands for the selected category
      } else {
        this.setState({ brands: [] }); // Clear brands if no category is selected
      }
    } else {
      this.setState({ [name]: value });
    }
  }

  render() {
    const { isOpen, setIsOpen } = this.props; // Access props here
    const filteredBrands = this.state.brands.filter((brand) => brand.brand_category === this.state.productCategory);

    const handleClose = () => {
      setIsOpen(false);
    };

  return (
    <div className='add-product-modal-container'>
      
      <Dialog open={isOpen} fullWidth>
        <form onSubmit={this.handleSubmit}>
        <DialogTitle>Create Product</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="productCode"
            name="productCode"
            label="Product Code"
            type="text"
            fullWidth
            required
            variant="outlined"
            value={this.state.productCode}
            onChange={this.handleChange}
          />
          
          <TextField
            autoFocus
            margin="dense"
            id="productName"
            name="productName"
            label="Product Name"
            type="text"
            fullWidth
            required
            variant="outlined"
            value={this.state.productName}
            onChange={this.handleChange}
          />

          <TextField
            autoFocus
            margin="dense"
            id="productPrice"
            name="productPrice"
            label="Retail Price (kg)"
            type="number"
            fullWidth
            required
            variant="outlined"
            value={this.state.productPrice}
            onChange={this.handleChange}
          />

          <TextField
            autoFocus
            margin="dense"
            id="wholesalePrice"
            name="wholesalePrice"
            label="Wholesale Price (kg)"
            type="number"
            fullWidth
            required
            variant="outlined"
            value={this.state.wholesalePrice}
            onChange={this.handleChange}
          />

          <TextField
            autoFocus
            margin="dense"
            id="subDealerPrice"
            name="subDealerPrice"
            label="Sub Dealer Price"
            type="number"
            fullWidth
            required
            variant="outlined"
            value={this.state.subDealerPrice}
            onChange={this.handleChange}
          />

          <TextField
            autoFocus
            margin="dense"
            id="regCustomerPrice"
            name="regCustomerPrice"
            label="Regular Customer Price"
            type="number"
            fullWidth
            required
            variant="outlined"
            value={this.state.regCustomerPrice}
            onChange={this.handleChange}
          />

          <TextField
            autoFocus
            margin="dense"
            id="walkinCustomerPrice"
            name="walkinCustomerPrice"
            label="WalkIn Customer Price"
            type="number"
            fullWidth
            required
            variant="outlined"
            value={this.state.walkinCustomerPrice}
            onChange={this.handleChange}
          />

          <TextField
            margin="dense"
            id="productCategory"
            name="productCategory"
            label="Category"
            value={this.state.productCategory}
            onChange={this.handleChange}
            select
            required
            fullWidth
            displayEmpty
          >
            <MenuItem disabled value=""><em>Select Category</em></MenuItem>
            {this.state.categories.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.category_name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            id="productBrand"
            name="productBrand"
            label="Product Brand"
            value={this.state.productBrand}
            onChange={this.handleChange}
            select
            fullWidth
            displayEmpty
          >
            <MenuItem value="" disabled><em>Select Brand</em></MenuItem>
            {filteredBrands.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.brand_name}
              </MenuItem>
            ))}
          </TextField>

        </DialogContent>
        <DialogActions sx={{padding:'30px'}}>
          <Button variant="contained" color="success" type="submit">Submit</Button>
          <Button variant="contained" color="error" onClick={handleClose}>Cancel</Button>
        </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
}

export default Add_Product_Modal
