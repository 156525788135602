import "./product_cart.css";
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';

import axios from 'axios';
import { BASE_URL } from "../../../base_url";
import CartDialog from "../../product-components/product-cart-modal/Product_Cart_Modal";
import ExitDialog from "../../alert-dialogs/exit-dialogs/Exit_Dialog";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';


function ProductCart({
  cart,
  setCart,
  customerType,
  setCustomerType,
}) {
  const [cashReceived, setCashReceived] = useState(0);
  const [total, setTotal] = useState(0);
  const [change, setChange] = useState(0);
  const [discount, setDiscount] = useState([]);
  const [customerNames, setCustomerNames] = useState([]);
  const [selectedCustomerName, setSelectedCustomerName] = useState('');
  const [selectedCustomerAddress, setSelectedCustomerAddress] = useState('')
  const selectedCustomerType = customerType;
  const [selectedpaymentMethod, setSelectedPaymentMethod] = useState('')
  const [checkedItems] = useState({});

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isExitDialogOpen, setExitDialogOpen] = useState(false);
  const [selectedDiscountName, setSelectedDiscountName] = useState('');
  

  // Cart Dialog
  const openCartDialog = (discountName) => {
    console.log('Discount Name:', discountName);
    setSelectedDiscountName(discountName);
    setDialogOpen(true);
  };

  const closeCartDialog = () => {
    setDialogOpen(false);
  };

  // Exit Dialog
  const openExitDialog = () => {
    setExitDialogOpen(true);
  };

  const closeExitDialog = () => {
    setExitDialogOpen(false);
  };

  // Function to calculate the total without discount
  useEffect(() => {
    let total = 0;
    cart.forEach((item) => {
      total += item.product_price * item.quantity;
    });
    setTotal(total);
  }, [cart, checkedItems]);

  // Fetch discounts and set them in state
  useEffect(() => {
    const url = BASE_URL + 'Get_Discount.php';
    axios.get(url)
      .then(response => response.data)
      .then(data => {
        setDiscount(data);
        console.log(data);
      })
  }, []);

   // Use the `useEffect` hook to calculate the change automatically
   useEffect(() => {
    // Calculate the change whenever `cashReceived` or `total` changes
    const changeValue = cashReceived - total;
    setChange(changeValue);
  }, [cashReceived, total]);

  // Fetch customer names and types
  useEffect(() => {
    const fetchData = async () => {
      const customerNamesUrl = BASE_URL + 'Get_Customer.php';
      const discountUrl = BASE_URL + 'Get_Discount.php';

      try {
        const [customerNamesResponse, discountResponse] = await Promise.all([
          axios.get(customerNamesUrl, { params: { customerType } }),
          axios.get(discountUrl),
        ]);

        const filteredCustomerNames = customerNamesResponse.data.filter(
          (customer) => customer.customer_type === customerType
        );

        setCustomerNames(filteredCustomerNames);
        setDiscount(discountResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [customerType]);

   // Event handler to update the selected customer's address
   const handleCustomerNameChange = (event) => {
    const selectedCustomerName = event.target.value;
  
    // Find the selected customer object based on the name
    const selectedCustomer = customerNames.find(
      (customer) => customer.id === selectedCustomerName
    );
  
    if (selectedCustomer) {
      // Set the selected customer name
      setSelectedCustomerName(selectedCustomerName);
  
      // Use the customer's associated address
      setSelectedCustomerAddress(selectedCustomer.customer_address);
    } else {
      setSelectedCustomerAddress(''); // Clear the address if no customer is selected
    }
  };

  return (
    <div className="cart-form">
      <div className="cart-header">

         {/* Customer Type */}
         <div className="cart-header-field">
          <Select
            id="customerType"
            name="customerType"
            value={customerType}
            onChange={(e) => setCustomerType(e.target.value)}
            sx={{ backgroundColor: 'white' }}
            fullWidth
            displayEmpty
          >
            <MenuItem disabled value="">
              <em>Select Type</em>
            </MenuItem>
            {discount.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.discount_name}
              </MenuItem>
            ))}
          </Select>
        </div>

        {/* Customer's Name */}
        <div className="cart-header-field">
        <Select
            id="customerName"
            name="customerName"
            sx={{ backgroundColor: 'white' }}
            fullWidth
            displayEmpty
            value={selectedCustomerName}
            onChange={handleCustomerNameChange} // Call the event handler when the customer name changes
          >
            <MenuItem disabled value="">
              <em>Select Customer</em>
            </MenuItem>
            {customerNames.map((customer, index) => (
              <MenuItem key={index} value={customer.id}>
                {customer.customer_name}
              </MenuItem>
            ))}
          </Select>
        </div>

        {/* Business's Name */}
        <div className="cart-header-field">
        <Select
          id="paymentMethod"
          name="paymentMethod"
          sx={{ backgroundColor: 'white' }}
          fullWidth
          displayEmpty
          value={selectedpaymentMethod}
          onChange={(e) => setSelectedPaymentMethod(e.target.value)} // Update the state variable
        >
          <MenuItem disabled value="">
            <em>Select Payment</em>
          </MenuItem>
          <MenuItem value="1">Cash</MenuItem>
          <MenuItem value="2">Credit</MenuItem>
          <MenuItem value="3">Cheque</MenuItem>
        </Select>
        </div>

        {/* Address */}
        <div className="cart-header-field">
          <TextField
            id="address"
            label="Address"
            variant="outlined"
            fullWidth
            sx={{ backgroundColor: 'white', borderRadius:'5px' }}
            value={selectedCustomerAddress} // Display the selected customer's address
          />
        </div>
      </div>

      {/* Cart Table */}
      <TableContainer component={Paper} sx={{ maxHeight: 450 }}>
        <Table stickyHeader sx={{ minWidth: 600 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell hidden>ITEM ID</TableCell>
              <TableCell align="center">DESCRIPTION</TableCell>
              <TableCell align="center">QUANTITY</TableCell>
              <TableCell align="center">UNIT</TableCell>
              <TableCell align="right">PRICE</TableCell>
              <TableCell align="right">TOTAL</TableCell>
              <TableCell align="right">ACTION</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {cart.map((item) => (
            <TableRow
              key={item.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" hidden>
                {item.id}
              </TableCell>
              <TableCell align="center">{item.product_name}</TableCell>
              <TableCell align="center" width="15">
                <Input
                  type="number"
                  value={item.quantity}
                  min="0"
                  max={customerType === 3 ? undefined : item.product_stocks}
                  onChange={(e) => {
                    const updatedQuantity = parseInt(e.target.value, 10);
                    if (!isNaN(updatedQuantity)) {
                      const newQuantity = customerType === 3 ? updatedQuantity : Math.min(updatedQuantity, item.product_stocks);
                      const updatedCart = cart.map((cartItem) =>
                        cartItem.id === item.id
                          ? { ...cartItem, quantity: newQuantity }
                          : cartItem
                      );
                      setCart(updatedCart);
                    }
                  }}
                />
              </TableCell>
              <TableCell align="center">{item.unit}</TableCell>
              <TableCell align="right">{item.product_price}</TableCell>
              <TableCell align="right">
                {item.product_price * item.quantity}
              </TableCell>
              <TableCell align="center">
                <IconButton
                  color="error"
                  onClick={() => {
                    const updatedCart = cart.filter((cartItem) => cartItem.id !== item.id);
                    setCart(updatedCart);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}

          </TableBody>
        </Table>
      </TableContainer>
      <br />

      {/* Cart Footer */}
      <div className="cart-footer">

      <div className="form-total">

        <div className="form-field">
          <h6>TOTAL PRICE:</h6>
          <TextField
            id="totalWithoutDiscount"
            variant="outlined"
            value={total}
            sx={{backgroundColor:'white' , borderRadius:'5px'}}
            disabled
          />
        </div>

        <div className="form-field">
          <h6>CASH RECEIVED:</h6>
          <TextField
            id="cashReceived"
            type="number"
            variant="outlined"
            sx={{ backgroundColor: 'white', borderRadius: '5px' }}
            value={cashReceived || ''}
            onChange={(e) => setCashReceived(parseFloat(e.target.value))}
          />
        </div>

        <div className="form-field">
          <h6>CHANGE:</h6>
          <TextField
            id="change"
            type="number"
            variant="outlined"
            sx={{ backgroundColor: 'white', borderRadius: '5px' }}
            value={change}
            disabled // Make it read-only
          />
        </div>

      </div>
      
        <div className="form-buttons">

          <div className="form-button">
          <Button
            variant="contained"
            size="large"
            color="success"
            startIcon={<AttachMoneyIcon />}
            fullWidth
            onClick={() => openCartDialog(cart.length > 0 ? cart[0].discount_name : '')}
            disabled={cart.length === 0 || !selectedCustomerType || !selectedCustomerName || !cashReceived || !selectedpaymentMethod}
          >
            Pay
          </Button>

          {/* Render the CartDialog component */}
          <CartDialog
            isOpen={isDialogOpen}
            onClose={closeCartDialog}
            cart={cart}
            selectedCustomerName={selectedCustomerName}
            total={total}
            selectedpaymentMethod={selectedpaymentMethod}
            cashReceived={cashReceived}
            change={change}
            selectedCustomerType={selectedCustomerType}
            selectedDiscountName={selectedDiscountName}
          />
            </div>      

          <div className="form-button">
            <Button
              variant="contained"
              color="error"
              size="large"
              fullWidth
              startIcon={<ExitToAppIcon />}
              onClick={openExitDialog}
            > 
              EXIT 
            </Button>

            <ExitDialog
              isOpen={isExitDialogOpen}
              onClose={closeExitDialog}
            />
              
          </div>

        </div>

        
    </div>

    </div>
  );
}

export default ProductCart;