import "./search_product.css";
import React, { useState } from 'react';

import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

function Search_Product({ onSearch }) {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = () => {
    // Call the onSearch prop with the search query
    onSearch(searchQuery);
  }
  return (
    <div className='search-product-cards-container'>
      <TextField
          id="outlined-start-adornment"
         fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon onClick={handleSearch} sx={{cursor:'pointer'}} />
              </InputAdornment>
            ),
          }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
    </div>
  )
}

export default Search_Product
