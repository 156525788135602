import "./login_page.css";
import React, { useState } from 'react';
import { BASE_URL } from "../../base_url";
import axios from 'axios';

import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import PosLogo from '../../assets/roguel_logo.png';

function Login_page() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the form from submitting and refreshing the page

    if (username.length === 0 || password.length === 0) {
      setError("Username and password are required.");
    } else {
      const url = BASE_URL + 'Login.php';
      const formData = new FormData();
      formData.append('username', username);
      formData.append('password', password);

      axios.post(url, formData)
        .then(response => {
          if (response.data.status === "Success") {
            const role = response.data.role; // Extract the role from the response
            const id = response.data.id;

            const randomNumber = Math.floor(Math.random() * 1001);
            document.cookie = `sessionID=${randomNumber};`;
            document.cookie = `userRole=${role};`;
            document.cookie = `userId=${id};`;
            window.location.replace("/dashboard");
          } else {
            setError("Incorrect username or password.");

            // Automatically clear the error message after 2 seconds
            setTimeout(() => {
              setError(null);
            }, 4000);
          }
        })
        .catch(error => {
          setError("An error occurred: " + error);

          // Automatically clear the error message after 2 seconds
          setTimeout(() => {
            setError(null);
          }, 4000);
        });
    }
  }

  return (
    <div className='login-page-container'>
      <div className='login-form'>
        <div className="login-logo">
          <img src={PosLogo} alt="logo" />
        </div>
        <form onSubmit={handleSubmit}>
        
          <TextField id="outlined-basic" label="Username" name="username" variant="outlined" value={username} onChange={(e) => setUsername(e.target.value)} fullWidth required />
          <TextField id="outlined-password-input" label="Password" name="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} autoComplete="current-password" fullWidth required />
          {error && <Alert severity="error">{error}</Alert>}
          <Divider><p>_</p></Divider>
          <center>
            <Button type='submit' variant="contained" color="success" sx={{ width: '80%', padding: '1rem', fontSize: '17px', fontWeight: 'bold',  backgroundColor: '#0f5236' }}>Log In</Button>
          </center>
        </form>
      </div>
    </div>
  )
}

export default Login_page;
