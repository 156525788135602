import "./discount_header.css";
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';

import AddDiscountModal from "../discount-add-modal/Add_Discount_Modal"

function Discount_Header() {
  const navigate = useNavigate();

  // OPEN ADD CATEGORY MODAL//
  const [isAddDiscountModalOpen, setIsAddDiscountModalOpen] = useState(false);

  const handleOpenAddDiscountModal = () => {
    setIsAddDiscountModalOpen(true);
  };

  const handleExitClick = () => {
    const shouldExit = window.confirm("Are you sure you want to exit?");
    if (shouldExit) {
      navigate('/customers');
      console.log("Exiting...");
    }
  };

  return (
    <div className='discount-header'>

      <div className="discount-header-search">
        <TextField
          id="outlined-start-adornment"
          sx={{ m: 1, width: '100%' }}
          InputProps={{
            endAdornment: <InputAdornment position="end"><SearchIcon/></InputAdornment>,
          }}
        />
      </div>

    <div className="discount-header-buttons">
      <div className="header-button">
        <Button variant="contained" size="large" onClick={handleOpenAddDiscountModal} >
          New Customer Type
        </Button>
      </div>

      <AddDiscountModal isOpen={isAddDiscountModalOpen} setIsOpen={setIsAddDiscountModalOpen} />
      
        <div className="header-button">
          <Button variant="contained" color="error" size="large" onClick={handleExitClick}>
            Exit
          </Button>
        </div>
      </div>  
      
    </div>
  )
}

export default Discount_Header
