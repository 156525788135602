import "./edit_brand_modal.css";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from "../../../base_url";

// Dialog Imports
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';

function Edit_Brand_Modal(props) {
  const [categories, setCategories] = useState([]);

  const [formData, setFormData] = useState({
    brandId: null,
    brandName: null,
    brandCategory: null
  });

  useEffect(() => {
    // When the modal is opened, populate the form with selected product data
    if (props.isOpen && props.selectedBrand) {
      const { id, brand_name, brand_category } = props.selectedBrand;
      setFormData({
        brandId: id,
        brandName: brand_name,
        brandCategory: brand_category
      });
    }
  }, [props.isOpen, props.selectedBrand]);

  useEffect(() => {
    // Fetch the list of categories when the component mounts
    axios.get(BASE_URL + 'Get_Category.php')
      .then((response) => {
        // Assuming the response is an array of category names
        setCategories(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('brandId', formData.brandId);
    formDataToSend.append('brandName', formData.brandName);
    formDataToSend.append('brandCategory', formData.brandCategory);

    axios({
      method: 'POST',
      url: BASE_URL + 'Get_Brand.php',
      data: formDataToSend,
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
    })
      .then((response) => {
        // Handle success
        console.log(response);
        alert('Brand Successfully Updated.');
        props.onClose(false);
        window.location.reload();
      })
      .catch((error) => {
        // Handle error
        console.log(error);
        alert('Brand Successfully Updated.');
        props.onClose(false);
        window.location.reload();
      });
  };

  const handleClose = () => {
    props.onClose(false);
  };

  return (
    <div className='edit-brand-modal-container'>
      <Dialog open={props.isOpen} fullWidth>
          <form onSubmit={handleSubmit}>
            <DialogTitle>Edit Brand</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="brandId"
                  name="brandId"
                  label="Brand ID"
                  type="text"
                  fullWidth
                  required
                  variant="outlined"
                  value={formData.brandId || ''}
                  onChange={handleChange}
                />

                <TextField
                  autoFocus
                  margin="dense"
                  id="brandName"
                  name="brandName"
                  label="Brand Name"
                  type="text"
                  fullWidth
                  required
                  variant="outlined"
                  value={formData.brandName || ''}
                  onChange={handleChange}
                />

              <TextField
                autoFocus
                margin="dense"
                id="brandCategory"
                name="brandCategory"
                label="Brand Category"
                fullWidth
                required
                variant="outlined"
                select
                value={formData.brandCategory || ''}
                onChange={handleChange}
              >
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.category_name}
                  </MenuItem>
                ))}
              </TextField>

              </DialogContent>
              <DialogActions sx={{ padding: '30px' }}>
                <Button variant="contained" color="success" type="submit">
                  Submit
                </Button>
                <Button variant="contained" color="error" onClick={handleClose}>
                  Cancel
                </Button>
                
            </DialogActions>
          </form>
        </Dialog>
    </div>
  )
}

export default Edit_Brand_Modal
