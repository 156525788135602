import "./add_category_modal.css";
import React from 'react';

import axios from 'axios';
import { BASE_URL } from "../../../base_url";

//Dialog Imports//
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

class Add_Category_Modal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      categoryName: '',
      perItem: false, // Add a state variable for the checkbox
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleCheckboxChange(event) {
    const state = this.state;
    state.perItem = event.target.checked; // Update the state when the checkbox changes
    this.setState(state);
  }

  handleSubmit(event) {
    event.preventDefault();
    // Check if required fields are empty
    if (!this.state.categoryName.trim()) {
      alert('Fields are empty. Please fill in all required fields.');
      return; // Do not submit the form
    }

    let formData = new FormData();
    formData.append('categoryName', this.state.categoryName);
    formData.append('perItem', this.state.perItem ? '1' : ''); // Send '1' if checked, else send empty string

    axios({
      method: 'POST',
      url: BASE_URL + 'Add_Category.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
    })
      .then((response) => {
        // Handle success
        console.log(response);
        alert('New Category Successfully Added.');
        window.location.reload();
      })
      .catch((error) => {
        // Handle error
        console.log(error);
      });
  }

    handleChange(event) {
      const state = this.state
      state[event.target.name] = event.target.value
      this.setState(state);
    }

  render() {

    const { isOpen, setIsOpen } = this.props; // Access props here

    const handleClose = () => {
      setIsOpen(false);
    };

    return (
      <div className="add-category-modal-container">
        <Dialog open={isOpen} fullWidth>
          <form onSubmit={this.handleSubmit}>
            <DialogTitle>Create Category</DialogTitle>
            <DialogContent>

              <TextField
                autoFocus
                margin="dense"
                id="categoryName"
                name="categoryName"
                label="Category name"
                type="text"
                fullWidth
                required
                variant="outlined"
                value={this.state.categoryName}
                onChange={this.handleChange}
              /><br/><br/>

              <FormControlLabel
                label="Sold as per item"
                control={
                  <Checkbox
                    checked={this.state.perItem} // Bind the checkbox to state
                    onChange={this.handleCheckboxChange} // Handle checkbox changes
                  />
                }
              />

            </DialogContent>
              <DialogActions sx={{padding:'30px'}}>
                <Button variant="contained" color="success" onClick={this.handleSubmit}>Submit</Button>
                <Button variant="contained" color="error" onClick={handleClose}>Cancel</Button>
              </DialogActions>
              </form>
          </Dialog>
      </div>
    )
  }
}

export default Add_Category_Modal 
