import "./transactions.css";
import React, { useState } from 'react';

import Header from "../../components/header/Header";
import TransactionHeader from "../../components/transaction-components/transaction-header/Transaction_header"
import TransactionTable from "../../components/transaction-components/transaction-table/Transaction_Table"

function Transactions() {
  const [searchQuery, setSearchQuery] = useState('');
  return (
    <div className='transaction'>
      <Header/>

      <div className="transaction-table-header">
        <TransactionHeader onSearch={setSearchQuery}/>
      </div>

      <div className="transaction-table-content">
        <TransactionTable searchQuery={searchQuery}/>
      </div>
    </div>
  )
}

export default Transactions
