import "./brand_header.css";
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';

import AddBrandModal from "../brand-add-modal/Add_Brand_Modal";

function Brand_header() {

  const navigate = useNavigate();

  // OPEN ADD CATEGORY MODAL//
  const [isAddBrandModalOpen, setIsAddBrandModalOpen] = useState(false);

  const handleOpenAddBrandModal = () => {
    setIsAddBrandModalOpen(true);
  };

  return (
    <div className='brand-header'>

      <div className="brand-header-search">
        <TextField
              id="outlined-start-adornment"
              sx={{ m: 1, width: '100%' }}
              InputProps={{
                endAdornment: <InputAdornment position="end"><SearchIcon/></InputAdornment>,
              }}
            />
      </div>
      
      <div className="brand-header-buttons">
        <div className="brand-button">
          <Button variant="contained" color="success" size="large" onClick={handleOpenAddBrandModal} >
            New Brand
          </Button>
        </div>
        
        <AddBrandModal isOpen={isAddBrandModalOpen} setIsOpen={setIsAddBrandModalOpen} />

          <div className="brand-button">
            <Button variant="contained" color="error" size="large" onClick={() => navigate('/products')}>
              Exit
            </Button>
          </div>
        </div>
    </div>
  )
}

export default Brand_header
