import "./edit_product_modal.css";
import React, { useState, useEffect } from 'react';

// Dialog Imports
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';

import axios from 'axios';
import { BASE_URL } from "../../../base_url";

function Edit_Product_Modal(props) {
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  
  const [formData, setFormData] = useState({
    productId: null,
    productCode: null,
    productName: null,
    productCategory: null,
    productBrand: null,
  });

  useEffect(() => {
    // When the modal is opened, populate the form with selected product data
    if (props.isOpen && props.selectedProduct) {
      const { id, product_code, product_name, category_name, product_price, wholesale_price_kilo, subdealer_price, reg_customer_price, walkin_customer_price, brand_name } = props.selectedProduct;
      const selectedCategory = categories.find(category => category.category_name === category_name);
      const selectedBrand = brands.find(brand => brand.brand_name === brand_name);
      setFormData({
        productId: id,
        productCode: product_code,
        productName: product_name,
        productPrice: product_price,
        wholesalePrice: wholesale_price_kilo,
        subDealerPrice: subdealer_price,
        regCustomerPrice: reg_customer_price,
        walkinCustomerPrice: walkin_customer_price,
        productCategory: selectedCategory ? selectedCategory.id : null,
        productBrand: selectedBrand ? selectedBrand.id : null,
      });
    }
  }, [props.isOpen, props.selectedProduct, categories, brands]);

  useEffect(() => {
    // Fetch the list of categories when the component mounts
    axios.get(BASE_URL + 'Get_Category.php')
      .then((response) => {
        // Assuming the response is an array of category names
        setCategories(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    // Fetch the list of product brands when the component mounts
    axios.get(BASE_URL + 'Get_Brand.php')
      .then((response) => {
        // Assuming the response is an array of brand names
        setBrands(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('productId', formData.productId);
    formDataToSend.append('productCode', formData.productCode);
    formDataToSend.append('productName', formData.productName);
    formDataToSend.append('productPrice', formData.productPrice);
    formDataToSend.append('wholesalePrice', formData.wholesalePrice);
    formDataToSend.append('subDealerPrice', formData.subDealerPrice);
    formDataToSend.append('regCustomerPrice', formData.regCustomerPrice);
    formDataToSend.append('walkinCustomerPrice', formData.walkinCustomerPrice);
    formDataToSend.append('productPrice', formData.productPrice);
    formDataToSend.append('productCategory', formData.productCategory);
    formDataToSend.append('productBrand', formData.productBrand);

    axios({
      method: 'POST',
      url: BASE_URL + 'Get_Product.php',
      data: formDataToSend,
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
    })
      .then((response) => {
        // Handle success
        console.log(response);
        alert('Product Successfully Updated.');
        props.onClose(false);
        window.location.reload();
      })
      .catch((error) => {
        // Handle error
        console.log(error);
        alert('Product Successfully Updated.');
        props.onClose(false);
        window.location.reload();
      });
  };

  const handleClose = () => {
    props.onClose(false);
  };

  return (
      <div className="edit-product-modal-container">
        <Dialog open={props.isOpen} fullWidth>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Edit Product</DialogTitle>
            <DialogContent>

              <TextField
                autoFocus
                margin="dense"
                id="productId"
                name="productId"
                label="Product ID"
                type="number"
                disabled
                hidden
                value={formData.productId || ''}
              />

              <TextField
                autoFocus
                margin="dense"
                id="productCode"
                name="productCode"
                label="Product Code"
                type="number"
                fullWidth
                required
                variant="outlined"
                value={formData.productCode || ''}
                onChange={handleChange}
              />

              <TextField
                autoFocus
                margin="dense"
                id="productName"
                name="productName"
                label="Product Name"
                type="text"
                fullWidth
                required
                variant="outlined"
                value={formData.productName || ''}
                onChange={handleChange}
              />

              <TextField
                autoFocus
                margin="dense"
                id="productPrice"
                name="productPrice"
                label="Retail Price (kg)"
                type="number"
                fullWidth
                required
                variant="outlined"
                value={formData.productPrice || ''}
                onChange={handleChange}
              />

              <TextField
                autoFocus
                margin="dense"
                id="wholesalePrice"
                name="wholesalePrice"
                label="Wholesale Price (kg)"
                type="number"
                fullWidth
                required
                variant="outlined"
                value={formData.wholesalePrice || ''}
                onChange={handleChange}
              />

              <TextField
                autoFocus
                margin="dense"
                id="subDealerPrice"
                name="subDealerPrice"
                label="Sub Dealer Price (s)"
                type="number"
                fullWidth
                required
                variant="outlined"
                value={formData.subDealerPrice || ''}
                onChange={handleChange}
              />

              <TextField
                autoFocus
                margin="dense"
                id="regCustomerPrice"
                name="regCustomerPrice"
                label="Regular Customer Price"
                type="number"
                fullWidth
                required
                variant="outlined"
                value={formData.regCustomerPrice || ''}
                onChange={handleChange}
              />

              <TextField
                autoFocus
                margin="dense"
                id="walkinCustomerPrice"
                name="walkinCustomerPrice"
                label="Walkin Customer Price"
                type="number"
                fullWidth
                required
                variant="outlined"
                value={formData.walkinCustomerPrice || ''}
                onChange={handleChange}
              />

              <TextField
                margin="dense"
                id="productCategory"
                name="productCategory"
                label="Product Category"
                fullWidth
                required
                variant="outlined"
                select
                value={formData.productCategory || ''}
                onChange={handleChange}
              >
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}> {/* Match value with category_name */}
                    {category.category_name}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                margin="dense"
                id="productBrand"
                name="productBrand"
                label="Product Brand"
                fullWidth
                variant="outlined"
                select
                value={formData.productBrand || ''}
                onChange={handleChange}
              >
                {brands.map((brand) => (
                  <MenuItem key={brand.id} value={brand.id}>
                    {brand.brand_name}
                  </MenuItem>
                ))}
              </TextField>

            </DialogContent>
            <DialogActions sx={{ padding: '30px' }}>
              <Button variant="contained" color="success" type="submit">
                Submit
              </Button>
              <Button variant="contained" color="error" onClick={handleClose}>
                Cancel
              </Button>
              
            </DialogActions>
            </form>
          </Dialog>
      </div>
    )
  }
export default Edit_Product_Modal;
