import "./product_table.css";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from "../../../base_url";
import EditProductModal from "../../product-components/product-edit-modal/Edit_Product_Modal";

//TABLE IMPORTS//
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor:'gray',
    color: theme.palette.common.white,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


function ProductTable({ searchQuery }) {
  const [products, setProducts] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    const url = BASE_URL + 'Get_Product.php';
    axios.get(url)
      .then(response => response.data)
      .then(data => {
        setProducts(data);
        console.log(data);
      });
  }, []);

  const filteredProducts = products.filter((item) =>
  item.product_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
  item.category_name.toLowerCase().includes(searchQuery.toLowerCase())
);

  // Function to open the Edit Modal
  const openEditModal = (product) => {
    setSelectedProduct(product);
    setIsEditModalOpen(true);
  };

  // Function to close the Edit Modal
  const closeEditModal = () => {
    setSelectedProduct(null);
    setIsEditModalOpen(false);
  };

  const deleteProduct = (id, event) => {
    event.preventDefault();
    if (window.confirm("Are you sure want to delete?")) {
      axios({
        method: 'POST',
        url: BASE_URL + 'Get_Product.php/?delete=' + id
      })
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          alert("Product deleted successfully");
          window.location.reload();
        }
      })
      .catch(function (response) {
        alert("Product deleted successfully");
        window.location.reload();
        console.log(response);
      });
    }
  };

  if (products.length > 0) {
    return (
      <div className='product-table'>
      <TableContainer component={Paper} sx={{ maxHeight: 680 }}>
        <Table stickyHeader sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Product Code</StyledTableCell>
              <StyledTableCell align="center">Product Name</StyledTableCell>
              <StyledTableCell align="center">Retail Price (kg)</StyledTableCell>
              <StyledTableCell align="center">Wholesale Price (kg)</StyledTableCell>
              <StyledTableCell align="center">Sub Dealer Price (s)</StyledTableCell>
              <StyledTableCell align="center">Regular Customer Price (s)</StyledTableCell>
              <StyledTableCell align="center">Walk-In Customer Price (s)</StyledTableCell>
              <StyledTableCell align="center" width="200">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {filteredProducts.map((item) => ( // Use 'products' instead of 'this.state.products'
              <StyledTableRow key={item.id}>
                <StyledTableCell component="th" scope="row">{item.product_code}</StyledTableCell>
                <StyledTableCell align="center">{item.product_name}</StyledTableCell>
                <StyledTableCell align="center">{item.product_price}</StyledTableCell>
                <StyledTableCell align="center">{item.wholesale_price_kilo}</StyledTableCell>
                <StyledTableCell align="center">{item.subdealer_price}</StyledTableCell>
                <StyledTableCell align="center">{item.reg_customer_price}</StyledTableCell>
                <StyledTableCell align="center">{item.walkin_customer_price}</StyledTableCell>
                <StyledTableCell align="center">
                  <Button variant="contained" color="success" fullWidth onClick={() => openEditModal(item)}>
                    Edit
                  </Button>
                  &nbsp;
                  <Button variant="contained" color="error" fullWidth onClick={(event) => deleteProduct(item.id, event)}>
                    Delete
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Render the Edit_Product_Modal */}
      {isEditModalOpen && (
        <EditProductModal
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
          selectedProduct={selectedProduct}
        />
      )}
    </div>
    )
  }
  else {
    return (
      <div id="containers">
        No record found
      </div>
    );
  }
}
export default ProductTable
