import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { BASE_URL } from "../../../base_url";

export default function Dash_chart() {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch data from PHP page
    fetch(BASE_URL + "Get_Chart.php") // Replace 'your_php_page_url.php' with the actual URL to your PHP page
      .then(response => response.json())
      .then(result => {
        // Process the received data to match the format needed by Recharts
        const formattedData = Object.keys(result).map(key => ({
          name: key,
          MonthlyTransactions: result[key]
        }));
        setData(formattedData);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []); // Empty dependency array to run this effect only once

  return (
    <div className='dash-chart' style={{ width: '100%', height: 430 }}>
      <h4>SALES ANALYTICS</h4>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 25,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis className="YAxis" />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="MonthlyTransactions" stroke="#64381d" activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
