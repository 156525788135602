import "./inventory_table.css";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from "../../../base_url";
import EditInventoryModal from "../../inventory-component/inventory-edit-modal/Edit_Inventory_Modal";

// TABLE IMPORTS
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'gray',
    color: theme.palette.common.white,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function Inventory_table({ searchQuery }) {
  const [productInventory, setProductInventory] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedProductInventory, setSelectedproductInventory] = useState(null);

  useEffect(() => {
    const url = BASE_URL + 'Get_Inventory.php';
    axios.get(url)
      .then(response => response.data)
      .then(data => {
        setProductInventory(data); // Fixed the variable name here
      });
  }, []);

  const filteredProducts = productInventory.filter((item) =>
  item.product_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
  item.category_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Function to open the Edit Modal
  const openEditModal = (productInventory) => {
    setSelectedproductInventory(productInventory);
    setIsEditModalOpen(true);
  };

  // Function to close the Edit Modal
  const closeEditModal = () => {
    setSelectedproductInventory(null);
    setIsEditModalOpen(false);
  };

  const handleResetButtonClick = (id) => {
    const shouldReset = window.confirm("Are you sure you want to reset the inventory?");
  
    if (shouldReset) {
      const resetUrl = BASE_URL + 'Reset_Inventory.php/?reset=' + id;
  
      axios.post(resetUrl)
        .then(response => {
          // Handle the response if needed
          console.log(response.data);
          alert("Inventory reset successfully");
          window.location.reload();
        })
        .catch(error => {
          // Handle errors if necessary
          console.error('Error resetting inventory:', error);
          alert("Error resetting inventory");
        });
    } else {
      // Handle the case where the user cancels the reset
      console.log("Reset canceled");
    }
  };


  return (
    <div className="inventory-table">
      <TableContainer component={Paper} sx={{ maxHeight: 680 }}>
        <Table stickyHeader sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Product Code</StyledTableCell>
              <StyledTableCell align="center">Product Name</StyledTableCell>
              <StyledTableCell align="center">Category</StyledTableCell>
              <StyledTableCell align="center">Total Retail Stocks</StyledTableCell>
              <StyledTableCell align="center">Total Wholesale Stocks</StyledTableCell>
              <StyledTableCell align="center">Total Retail Sold</StyledTableCell>
              <StyledTableCell align="center">Total Wholesale Sold</StyledTableCell>
              <StyledTableCell align="center" width="200">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredProducts.map((item) => (
              <StyledTableRow key={item.id}>
                <StyledTableCell component="th" scope="row" hidden>{item.id}</StyledTableCell>
                <StyledTableCell component="th" scope="row">{item.product_code}</StyledTableCell>
                <StyledTableCell align="center">{item.product_name}</StyledTableCell>
                <StyledTableCell align="center">{item.category_name}</StyledTableCell>
                <StyledTableCell align="center">{item.retail_stocks}</StyledTableCell>
                <StyledTableCell align="center">{item.wholesale_stocks}</StyledTableCell>
                <StyledTableCell align="center">{item.retail_sold}</StyledTableCell>
                <StyledTableCell align="center">{item.wholesale_sold}</StyledTableCell>
                <StyledTableCell align="center">
                  <Button variant="contained" color="success" fullWidth onClick={() => openEditModal(item)}>
                    Edit
                  </Button>&nbsp;
                  <Button variant="contained" color="info" fullWidth onClick={() => handleResetButtonClick(item.id)}>
                    Reset
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
       {/* Render the Edit_Inventory_Modal */}
       {isEditModalOpen && (
        <EditInventoryModal
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
          selectedProductInventory= {selectedProductInventory}
        />
      )}
    </div>
  )
}

export default Inventory_table
