import "./products.css";
import React, { useState } from 'react';

import Header from "../../components/header/Header";
import ProductHeader from "../../components/product-components/product-header/Product_header"
import ProductTable from "../../components/product-components/product-table/Product_Table"

function Products() {
  const [searchQuery, setSearchQuery] = useState('');
  return (
    <div className='products'>
      <Header/>

      <div className="product-table-header">
        <ProductHeader onSearch={setSearchQuery}/>
      </div>
      <div className="product-table-content">
        <ProductTable searchQuery={searchQuery}/>
      </div>
    </div>
  )
}

export default Products
