import React from 'react';
import { useNavigate } from 'react-router-dom';
import "./footer.css";

import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';

function Footer({ userRole }) {
  const navigate = useNavigate();

  return (
    <div className='footer'>
      <div className='icon' onClick={() => navigate('/dashboard')}>
        <DashboardOutlinedIcon color='primary' fontSize='large' sx={{ color: '#64381d' }} /><br />
        <h6>Dashboard</h6>
      </div>

      {userRole === '1' && (
        <div className='icon' onClick={() => navigate('/users')}>
          <PeopleAltOutlinedIcon color='primary' fontSize='large' sx={{ color: '#64381d' }} /><br />
          <h6>Users</h6>
        </div>
      )}

      {userRole === '1' && (
        <div className='icon' onClick={() => navigate('/reports')}>
          <LocalPrintshopOutlinedIcon color='primary' fontSize='large' sx={{ color: '#64381d' }} /><br />
          <h6>Print Reports</h6>
        </div>
      )}
    </div>
  )
}

export default Footer;
