import "./product_brand.css";
import React from 'react';

import Header from "../../components/header/Header";
import BrandHeader from "../../components/product-components/brand-header/Brand_header"
import BrandTable from "../../components/product-components/brand-table/Brand_table"

function Product_brand() {
  return (
    <div className="product-brand">
      <Header/>
      <div className="brand-table-header">
        <BrandHeader/>
      </div>
      <div className="brand-table-content">
        <BrandTable/>
      </div>
    </div>
  )
}

export default Product_brand
