import "./inventory.css";
import React, { useState } from 'react';

import Header from "../../components/header/Header";
import InventoryHeader from "../../components/inventory-component/inventory-header/Inventory_header"
import InventoryTable from "../../components/inventory-component/inventory-table/Inventory_table"

function Inventory() {
  const [searchQuery, setSearchQuery] = useState('');
  return (
    <div className="inventory">
      <Header/>

      <div className="inventory-table-header">
        <InventoryHeader onSearch={setSearchQuery}/>
      </div>

      <div className="inventory-table-content">
        <InventoryTable searchQuery={searchQuery}/>
      </div>

    </div>
  )
}

export default Inventory
