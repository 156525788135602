import "./users.css";
import React, { useState } from 'react';

import Header from "../../components/header/Header";
import UserHeader from "../../components/users-components/user-header/User_header";
import UserTable from "../../components/users-components/user-table/User_Table";

function Users() {
  const [searchQuery, setSearchQuery] = useState('');
  return (
    <div className="users">
      <Header/>

      <div className="user-table-header">
        <UserHeader  onSearch={setSearchQuery}/>
      </div>
      <div className="user-table-content">
        <UserTable searchQuery={searchQuery}/>
      </div>
    </div>
  )
}

export default Users
