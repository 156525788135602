import "./product_category.css";
import React from 'react';

import Header from "../../components/header/Header";
import CategoryTable from "../../components/product-components/category-table/Category_table"
import CategoryHeader from "../../components/product-components/category-header/Category_header"

class Product_category extends React.Component {
  render() {
    return (
      <div className="product-category">
        <Header/>
        <div className="category-table-header">
          <CategoryHeader/>
        </div>
        <div className="category-table-content">
          <CategoryTable/>
        </div>
      </div>
    )
  }
}

export default Product_category
