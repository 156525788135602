import './inventory_header.css';
import React, { useState } from 'react';
import axios from 'axios';
import { BASE_URL } from "../../../base_url";

import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';

import ExitDialog from '../../alert-dialogs/exit-dialogs/Exit_Dialog';

function Inventory_header({ onSearch }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [isExitDialogOpen, setExitDialogOpen] = useState(false);

  const handleSearch = () => {
    // Call the onSearch prop with the search query
    onSearch(searchQuery);
  }

  // Exit Dialog
  const openExitDialog = () => {
    setExitDialogOpen(true);
  };

  const closeExitDialog = () => {
    setExitDialogOpen(false);
  };

  const handleResetAllButtonClick = () => {
    const shouldResetAll = window.confirm("Are you sure you want to reset all inventory?");

    if (shouldResetAll) {
      const resetAllUrl = BASE_URL + 'Reset_All_Inventory.php';

      axios.post(resetAllUrl)
        .then(response => {
          // Handle the response if needed
          console.log(response.data);
          window.location.reload();
          alert("All inventory reset successfully");
        })
        .catch(error => {
          // Handle errors if necessary
          console.error('Error resetting all inventory:', error);
        });
    } else {
      // Handle the case where the user cancels the reset
      console.log("Reset All canceled");
    }
  };

  return (
    <div className='inventory-header'>
      <div className="inventory-header-search">
        <TextField
          id="outlined-start-adornment"
          sx={{ m: 1, width: '100%' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon onClick={handleSearch} sx={{cursor:'pointer'}} />
              </InputAdornment>
            ),
          }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      <div className="inventory-header-buttons">
        <div className="inventory-button">
          <Button variant="contained" color="success" fullWidth size="large" onClick={handleResetAllButtonClick}>
            Reset All
          </Button>
        </div>
        <div className="inventory-button">
          <Button variant="contained" color="error" fullWidth size="large" onClick={openExitDialog}>
            Exit
          </Button>
          <ExitDialog
            isOpen={isExitDialogOpen}
            onClose={closeExitDialog}
          />
        </div>
      </div>

    </div>
  )
}

export default Inventory_header
