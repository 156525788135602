import "./my_account_modal.css";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from "../../base_url";

// Dialog Imports
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

function My_Acount_Modal({ isOpen, onClose, userData, userId }) {
  
  const [formData, setFormData] = useState({
    id: '',
    username: '',
    password: '',
  });

  const handleClose = () => {
    onClose(false);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    // Send a POST request to update user data using Axios
    axios.post(`${BASE_URL}Get_Account.php`, {
      userId: userId,
      newUsername: formData.username,
      newPassword: formData.password,
    })
      .then((response) => {
        // Handle the response from the server, e.g., show a success message
        alert('Account Successfully Updated.');
        window.location.reload();
        console.log(response.data);
      })
      .catch((error) => {
        // Handle errors, e.g., display an error message
        console.error(error);
      });
  };


  useEffect(() => {
    // When the modal is opened, populate the form with selected account data
    if (isOpen && userData) {
      const { id, username, password } = userData;
      setFormData({
        id: id,
        username: username,
        password: password,
      });
    }
  }, [isOpen, userData]);
  
  return (
    <div className="my-account-container">
      <Dialog open={isOpen} fullWidth>
          <form onSubmit={handleFormSubmit}>
            <DialogTitle>My Account</DialogTitle>
              <DialogContent>
                
                <TextField
                  id="id"
                  name="id"
                  hidden
                  value={formData.id}
                  fullWidth
                />

                <TextField
                  autoFocus
                  margin="dense"
                  id="username"
                  name="username"
                  label="Username"
                  type="text"
                  fullWidth
                  required
                  variant="outlined"
                  value={formData.username}
                  onChange={(e) => setFormData({ ...formData, username: e.target.value })}
                />
                
                <TextField
                  autoFocus
                  margin="dense"
                  id="password"
                  name="password"
                  label="Create new password"
                  type="password"
                  fullWidth
                  required
                  variant="outlined"
                  value={formData.password}
                  onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                />

              </DialogContent>
              <DialogActions sx={{ padding: '30px' }}>
                <Button variant="contained" color="success" type="submit">
                  Submit
                </Button>
                <Button variant="contained" color="error" onClick={handleClose}>
                  Cancel
                </Button>
            </DialogActions>
          </form>
        </Dialog>
    </div>
  )
}

export default My_Acount_Modal
