import "./transaction_header.css";
import React, { useState } from 'react';

import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import ExitDialog from "../../alert-dialogs/exit-dialogs/Exit_Dialog";

function Transaction_header({ onSearch }) {
  const [isExitDialogOpen, setExitDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = () => {
    // Call the onSearch prop with the search query
    onSearch(searchQuery);
  }

  const openExitDialog = () => {
    setExitDialogOpen(true);
  };

  const closeExitDialog = () => {
    setExitDialogOpen(false);
  };

  return (
    <div className="transaction-header">
      <div className="transaction-header-search">
        <TextField
          id="outlined-start-adornment"
          sx={{ m: 1, width: '100%' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon onClick={handleSearch} sx={{cursor:'pointer'}} />
              </InputAdornment>
            ),
          }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      <div className="transaction-header-buttons">
        <div className="transaction-button">
          <Button variant="contained" color="error" fullWidth size="large" onClick={openExitDialog}>
            Exit
          </Button>
          <ExitDialog
            isOpen={isExitDialogOpen}
            onClose={closeExitDialog}
          />
        </div>
      </div>

    </div>
  )
}

export default Transaction_header
