import "./view_transaction_modal.css";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from "../../../base_url";

// Dialog Imports
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

function View_Transaction_Modal(props) {

  const [orderedItems, setOrderedItems] = useState([]);

  const [formData, setFormData] = useState({
    transactionId: null,
    customerName: null,
    transactionDate: null,
    totalAmount: null,
    paymentMethod: null,
  });

  useEffect(() => {
    if (props.isOpen && props.selectedTransaction) {
      const { id, customer_name, total_amount, transaction_date, method_name } = props.selectedTransaction;
      setFormData({
        transactionId: id,
        customerName: customer_name,
        totalAmount: total_amount,
        transactionDate:transaction_date,
        paymentMethod: method_name
      });
      axios.get(BASE_URL + 'Get_Transaction.php', {
        params: {
          orderedItems: id, // Pass the selected transaction ID as 'orderedItems' to retrieve ordered items
        },
      })
        .then((response) => {
          setOrderedItems(response.data);
        })
        .catch((error) => {
          console.error('Error fetching ordered items data:', error);
        });
    }
  }, [props.isOpen, props.selectedTransaction]);

  const handleClose = () => {
    props.onClose(false);
  };


  return (
    <div className="view-customer-modal-container">
       <Dialog open={props.isOpen} fullWidth>
          <form>
            <DialogTitle>Transaction Information</DialogTitle>
              <DialogContent>

                <TextField
                  autoFocus
                  disabled
                  margin="dense"
                  id="transactionId"
                  name="transactionId"
                  label="transaction ID"
                  type="text"
                  fullWidth
                  required
                  variant="outlined"
                  value={formData.transactionId || ''}
                />

                <TextField
                  autoFocus
                  margin="dense"
                  id="customerName"
                  name="customerName"
                  label="Customer's Name"
                  type="text"
                  fullWidth
                  required
                  variant="outlined"
                  value={formData.customerName || ''}
                />

                <TextField
                  autoFocus
                  margin="dense"
                  id="transactionDate"
                  name="transactionDate"
                  label="Transaction Date"
                  type="text"
                  fullWidth
                  required
                  variant="outlined"
                  value={formData.transactionDate || ''}
                />

                <TextField
                  autoFocus
                  margin="dense"
                  id="totalAmount"
                  name="totalAmount"
                  label="Amount Purchased"
                  type="text"
                  fullWidth
                  required
                  variant="outlined"
                  value={formData.totalAmount ? parseFloat(formData.totalAmount).toLocaleString('en-US') : ''}
                />

                <TextField
                  autoFocus
                  margin="dense"
                  id="paymentMethod"
                  name="paymentMethod"
                  label="Payment Method"
                  type="text"
                  fullWidth
                  required
                  variant="outlined"
                  value={formData.paymentMethod || ''}
                />
          
                <TextField
                  autoFocus
                  margin="dense"
                  id="orderedItems"
                  name="orderedItems"
                  label="Ordered Items"
                  type="text"
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={5}
                  value={renderOrderedItems(orderedItems)}
                />

              </DialogContent>
              <DialogActions sx={{ padding: '30px' }}>
                <Button variant="contained" color="error" onClick={handleClose}>
                  Close
                </Button>
            </DialogActions>
          </form>
        </Dialog>
    </div>
  )

  function renderOrderedItems(orderedItems) {
    if (Array.isArray(orderedItems)) {
      return orderedItems.map((item) => (
        `Product Name: ${item.product_name}\n` +
        `Quantity: ${item.quantity}\n` +
        `Unit: ${item.unit}\n` +
        `Product Price: ${item.product_price}\n\n`
      )).join('');
    } else {
      return "No ordered items found";
    }
  }
}

export default View_Transaction_Modal
