import "./header.css";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import PersonIcon from '@mui/icons-material/Person';
import Avatar from '@mui/material/Avatar';
import HeaderLogo from '../../assets/roguels_trading.png';
import { brown } from '@mui/material/colors';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import AccountModal from '../my-account-modal/My_Acount_Modal';
import { BASE_URL } from "../../base_url";

function Header() {
  const navigate = useNavigate();
  const [sessionID, setSessionID] = useState(''); // Initialize sessionID state
  const [userRole, setUserRole] = useState('');
  const [userId, setUserId] = useState('');
  const [userData, setUserData] = useState(null);

  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);

  const handleOpenAccountModal = () => {
    setIsAccountModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsAccountModalOpen(false);
  };

  useEffect(() => {
    const sessionIDCookie = document.cookie.split(';').find(cookie => cookie.startsWith('sessionID='));
    const userRoleCookie = document.cookie.split(';').find(cookie => cookie.startsWith(' userRole='));
    const userIdCookie = document.cookie.split(';').find(cookie => cookie.startsWith(' userId='));
    if (!sessionIDCookie) {
      navigate('/');
    } else {
      const sessionIDValue = sessionIDCookie.split('=')[1]; // Extract sessionID
      setSessionID(sessionIDValue); // Set the sessionID state

      const userRoleValue = userRoleCookie ? userRoleCookie.split('=')[1] : '';
      setUserRole(userRoleValue);

      const userIdValue = userIdCookie ? userIdCookie.split('=')[1] : '';
      setUserId(userIdValue);
    }
  }, [navigate]);

  useEffect(() => {
    // Fetch user data based on userId when the component mounts
    if (userId) {
      axios.get(`${BASE_URL}Get_Account.php?userId=${userId}`)
        .then((response) => {
          setUserData(response.data); // Update state with user data
        })
        .catch((error) => {
          console.error('Error fetching user data:', error);
        });
    }
  }, [userId]);


  function handleLogout() {
    const confirmLogout = window.confirm("Are you sure you want to log out?");
    if (confirmLogout) {
      // Clear any data from session storage
      window.sessionStorage.clear();
      
      // Remove the sessionID and userRole cookies by setting their expiration date to a date in the past
      document.cookie = `sessionID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      document.cookie = `userRole=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      document.cookie = `userId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  
      // Redirect the user to the home page after logging out
      window.location.replace("/");
    }
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className='header'>
      <div className="logo">
       <img src={HeaderLogo} alt="logo"></img>
      </div>

      <div className="userInfo">
        <label hidden>{sessionID}</label>
        <label hidden>{userRole}</label>
        <label hidden>{userId}</label>
      </div>

      <div className="logout"  onClick={handleClick}>

          <h5>{userData ? userData.username : 'Loading...'}</h5>
          <Avatar
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            sx={{cursor:'pointer', bgcolor: brown[600] }}>
            <PersonIcon />
          </Avatar>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem disabled>{userData ? userData.username : 'Loading...'}</MenuItem>
            <MenuItem onClick={handleOpenAccountModal}>My account</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>

          {isAccountModalOpen &&(
          <AccountModal 
            isOpen={isAccountModalOpen} 
            onClose={handleCloseModal}
            userData={userData}
            userId={userId}
          />
          )}

      </div>

      <div className="mobile-logout">
        <Avatar
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{cursor:'pointer', bgcolor: brown[600] }}>
          <PersonIcon />
        </Avatar>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem disabled>{userData ? userData.username : 'Loading...'}</MenuItem>
          <MenuItem onClick={handleOpenAccountModal}>My account</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </div>
    </div>
  )
}

export default Header
