import "./edit_category_modal.css";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from "../../../base_url";

// Dialog Imports
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

function Edit_Category_Modal(props) {

  const [formData, setFormData] = useState({
    categoryId: null,
    categoryName: null
  });

  useEffect(() => {
    // When the modal is opened, populate the form with selected product data
    if (props.isOpen && props.selectedCategory) {
      const { id, category_name } = props.selectedCategory;
      setFormData({
        categoryId: id,
        categoryName: category_name,
      });
    }
  }, [props.isOpen, props.selectedCategory]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('categoryId', formData.categoryId);
    formDataToSend.append('categoryName', formData.categoryName);

    axios({
      method: 'POST',
      url: BASE_URL + 'Get_Category.php',
      data: formDataToSend,
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
    })
      .then((response) => {
        // Handle success
        console.log(response);
        alert('Category Successfully Updated.');
        props.onClose(false);
        window.location.reload();
      })
      .catch((error) => {
        // Handle error
        console.log(error);
        alert('Category Successfully Updated.');
        props.onClose(false);
        window.location.reload();
      });
  };

  const handleClose = () => {
    props.onClose(false);
  };

  return (
    <div className="edit-category-modal-container">

        <Dialog open={props.isOpen} fullWidth>
          <form onSubmit={handleSubmit}>
            <DialogTitle>Edit Category</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="categoryId"
                  name="categoryId"
                  label="Product ID"
                  type="text"
                  fullWidth
                  required
                  variant="outlined"
                  hidden
                  value={formData.categoryId || ''}
                  onChange={handleChange}
                />

                <TextField
                  autoFocus
                  margin="dense"
                  id="categoryName"
                  name="categoryName"
                  label="Category Name"
                  type="text"
                  fullWidth
                  required
                  variant="outlined"
                  value={formData.categoryName || ''}
                  onChange={handleChange}
                />


              </DialogContent>
              <DialogActions sx={{ padding: '30px' }}>
                <Button variant="contained" color="success" type="submit">
                  Submit
                </Button>
                <Button variant="contained" color="error" onClick={handleClose}>
                  Cancel
                </Button>
              </DialogActions>
              </form>
        </Dialog>
      
    </div>
  )
}

export default Edit_Category_Modal
