import "./edit_inventory_modal.css";
import React, { useState, useEffect } from 'react';

// Dialog Imports
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import axios from 'axios';
import { BASE_URL } from "../../../base_url";

function Edit_Inventory_Modal(props) {

  const [formData, setFormData] = useState({
    productId: null,
    productName: null,
    productCategory: null,
    retailStocks: null,
    wholesaleStocks: null,
  });

  useEffect(() => {
    // When the modal is opened, populate the form with selected product data
    if (props.isOpen && props.selectedProductInventory) {
      const { id, product_name, category_name, retail_stocks, wholesale_stocks, retail_sold, wholesale_sold } = props.selectedProductInventory;
      setFormData({
        productId: id,
        productName: product_name,
        productCategory: category_name,
        retailStocks: retail_stocks,
        wholesaleStocks: wholesale_stocks,
        retailSold: retail_sold,
        wholesaleSold: wholesale_sold,
      });
    }
  }, [props.isOpen, props.selectedProductInventory]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('productId', formData.productId);
    formDataToSend.append('retailStocks', formData.retailStocks);
    formDataToSend.append('wholesaleStocks', formData.wholesaleStocks);
    formDataToSend.append('retailSold', formData.retailSold);
    formDataToSend.append('wholesaleSold', formData.wholesaleSold);

    axios({
      method: 'POST',
      url: BASE_URL + 'Get_Inventory.php',
      data: formDataToSend,
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
    })
      .then((response) => {
        // Handle success
        console.log(response);
        alert('Stocks successfully Updated.');
        props.onClose(false);
        window.location.reload();
      })
      .catch((error) => {
        // Handle error
        console.log(error);
        alert('Stocks successfully Updated.');
        props.onClose(false);
        window.location.reload();
      });
  };

  const handleClose = () => {
    props.onClose(false);
  };

  return (
    <div className="edit-inventory-modal-container">
      <Dialog open={props.isOpen} fullWidth>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Edit Stocks</DialogTitle>
            <DialogContent>

              <TextField
                autoFocus
                disabled
                margin="dense"
                id="productId"
                name="productId"
                label="ID"
                type="text"
                fullWidth
                required
                variant="outlined"
                value={formData.productId || ''}
                onChange={handleChange}
              />

              <TextField
                autoFocus
                disabled
                margin="dense"
                id="productName"
                name="productName"
                label="Product Name"
                type="text"
                fullWidth
                required
                variant="outlined"
                value={formData.productName || ''}
                onChange={handleChange}
              />

              <TextField
                autoFocus
                disabled
                margin="dense"
                id="productCategory"
                name="productCategory"
                label="Product Category"
                type="text"
                fullWidth
                required
                variant="outlined"
                value={formData.productCategory || ''}
                onChange={handleChange}
              />

              <TextField
                autoFocus
                margin="dense"
                id="retailStocks"
                name="retailStocks"
                label="Retail Stocks"
                type="number"
                fullWidth
                required
                variant="outlined"
                value={formData.retailStocks || ''}
                onChange={handleChange}
              />

              <TextField
                autoFocus
                margin="dense"
                id="wholesaleStocks"
                name="wholesaleStocks"
                label="Wholesale Stocks"
                type="number"
                fullWidth
                required
                variant="outlined"
                value={formData.wholesaleStocks || ''}
                onChange={handleChange}
              />

              <TextField
                autoFocus
                margin="dense"
                id="retailSold"
                name="retailSold"
                label="Total Retail Sold"
                type="number"
                fullWidth
                variant="outlined"
                value={formData.retailSold || ''}
                onChange={handleChange}
              />

              <TextField
                autoFocus
                margin="dense"
                id="wholesaleSold"
                name="wholesaleSold"
                label="Total Wholesale Sold"
                type="number"
                fullWidth
                variant="outlined"
                value={formData.wholesaleSold || ''}
                onChange={handleChange}
              />

            </DialogContent>
            <DialogActions sx={{ padding: '30px' }}>
              <Button variant="contained" color="success" type="submit">
                Submit
              </Button>
              <Button variant="contained" color="error" onClick={handleClose}>
                Cancel
              </Button>
            </DialogActions>
            </form>
          </Dialog>
    </div>
  )
}

export default Edit_Inventory_Modal
