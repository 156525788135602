import "./add_brand_modal.css";
import React from 'react';

import axios from 'axios';
import { BASE_URL } from "../../../base_url";

//Dialog Imports//
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

class Add_Brand_Modal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        brandName:'',
        brandCategory:'',
        categories: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
      const url = BASE_URL + 'Get_Category.php';
      axios.get(url).then(response => response.data)
        .then((data) => {
          this.setState({ categories: data });
          console.log(this.state.categories);
        });
  }

  handleSubmit(event) {
    event.preventDefault();
    // Check if required fields are empty
    if (
      !this.state.brandName.trim()
    ) {
      alert('Fields are empty. Please fill in all required fields.');
      return; // Do not submit the form
    }
  
    let formData = new FormData();
    formData.append('brandName', this.state.brandName);
    formData.append('brandCategory', this.state.brandCategory);
  
    axios({
      method: 'POST',
      url: BASE_URL + 'Add_Brand.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
    })
      .then((response) => {
        // Handle success
        console.log(response);
        alert('Brand Successfully Added.');
        window.location.reload();
      })
      .catch((error) => {
        // Handle error
        console.log(error);
      });
  }

  handleChange(event) {
    const state = this.state
    state[event.target.name] = event.target.value
    this.setState(state);
  }

  render() {
    const { isOpen, setIsOpen } = this.props; // Access props here

    const handleClose = () => {
      setIsOpen(false);
    };
    return (
      <div className="add-brand-modal-container">
        <Dialog open={isOpen} fullWidth>
          <form onSubmit={this.handleSubmit}>
            <DialogTitle>Create Brand</DialogTitle>
            <DialogContent>

              <TextField
                autoFocus
                margin="dense"
                id="brandName"
                name="brandName"
                label="Brand Name"
                type="text"
                fullWidth
                required
                variant="outlined"
                value={this.state.brandName}
                onChange={this.handleChange}
              />

            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="brandCategory"
              name="brandCategory"
              label="Category"
              value={this.state.brandCategory}
              onChange={this.handleChange}
              fullWidth
              displayEmpty
            >
              <MenuItem value="" disabled>Select Category</MenuItem>
              {this.state.categories.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.category_name}
                </MenuItem>
              ))}
            </Select>

            </DialogContent>
              <DialogActions sx={{padding:'30px'}}>
                <Button variant="contained" color="success" onClick={this.handleSubmit}>Submit</Button>
                <Button variant="contained" color="error" onClick={handleClose}>Cancel</Button>
              </DialogActions>
              </form>
          </Dialog>
      </div>
    )
  }
}

export default Add_Brand_Modal;
