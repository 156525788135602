import "./add_discount_modal.css";
import React from 'react';

import axios from 'axios';
import { BASE_URL } from "../../../base_url";

//Dialog Imports//
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

class Add_Discount_Modal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        discountName:''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    // Check if required fields are empty
    if (
      !this.state.discountName.trim()
    ) {
      alert('Fields are empty. Please fill in all required fields.');
      return; // Do not submit the form
    }
  
    let formData = new FormData();
    formData.append('discountName', this.state.discountName);
  
    axios({
      method: 'POST',
      url: BASE_URL + '/src/php/Add_Discount.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
    })
      .then((response) => {
        // Handle success
        console.log(response);
        alert('New Discount Successfully Added.');
        window.location.reload();
      })
      .catch((error) => {
        // Handle error
        console.log(error);
      });
  }

  handleChange(event) {
    const state = this.state
    state[event.target.name] = event.target.value
    this.setState(state);
  }

  render() {
    const { isOpen, setIsOpen } = this.props; // Access props here

    const handleClose = () => {
      setIsOpen(false);
    };
    return (
      <div className='add-discount-modal-container'>
        <Dialog open={isOpen} fullWidth>
          <form onSubmit={this.handleSubmit}>
            <DialogTitle>Create Customer Type</DialogTitle>
            <DialogContent>

              <TextField
                autoFocus
                margin="dense"
                id="discountName"
                name="discountName"
                label="Customer Type"
                type="text"
                fullWidth
                required
                variant="outlined"
                value={this.state.discountName}
                onChange={this.handleChange}
              />

            </DialogContent>
              <DialogActions sx={{padding:'30px'}}>
                <Button variant="contained" color="error" onClick={handleClose}>Cancel</Button>
                <Button variant="contained" onClick={this.handleSubmit} >Submit</Button>
              </DialogActions>
              </form>
          </Dialog>
      </div>
    )
  }
}

export default Add_Discount_Modal
